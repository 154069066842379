// Titles

.title {
  font-weight: var(--font-weight-bold);

    a {
      color: inherit;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

}

.title--xxl {
  font-size: var(--fs-fluid-xxl);
  line-height: var(--line-height-sm);
}

.title--xl {
  font-size: var(--fs-fluid-xl);
  line-height: var(--line-height-sm);
}

.title--lg {
  font-size: var(--fs-fluid-lg);
  line-height: var(--line-height-sm);
}

.title--md {
  font-size: var(--fs-fluid-md);
  line-height: var(--line-height-md);
}

.title--sm {
  font-size: var(--fs-fluid-sm);
  line-height: var(--line-height-lg);
}

// Copy

.summary-text {
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  line-height: var(--line-height-lg);
}

.supporting-text {
  line-height: var(--line-height-lg);
}

// Colors

.text-white {
  color: white;
}

.text-white-fade {
  color: var(--color-white-type);
}

.text-blue-dark {
  color: var(--color-blue-dark)
}

.text-blue-mid {
  color: var(--color-blue-mid);
}

.text-lumo-green {
  color: var(--color-lumo-green);
}