.filters {
  display: grid;
  gap: 0.5rem;
}

.filter-list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.filter {
  display: block;
  padding: 0.5rem;

  font-size: 1.4rem;
  font-weight: var(--font-weight-bold);
  color: white;

  text-decoration: none;

   

}

.filter--white {
  background-color: white;
  color: var(--color-blue-dark);

    &:hover {
      background-color: var(--color-lumo-green);
    }

}

.filter--blue-dark {
  background-color: var(--color-blue-dark);
  color: white;

    &:hover {
      opacity: var(--hover-opacity);
    }
    
}
