.related-content-intro {
  position: relative;
  display: grid;
  gap: var(--padding-sm);
  width: 100%;
  padding: var(--padding-lg) var(--padding-lg) var(--padding-lg) 0;

  background-color: var(--color-purple-light);

    @include mq(screen-sm) {
      width: 66%;
    }

    &:before {
      content: "";

      position: absolute;
      top: 0;
      right: 100%;
      
      width: var(--padding-lg);
      height: 100%;

      background-color: var(--color-purple-light);

        @include mq(screen-xxl) {
          width: calc((var(--mq-xxl) - var(--mq-xl))/2); // The overspill needed to align with a max-width xxl container
        }

    }

}

.related-content-feed {
  position: relative;

  display: grid;
  gap: var(--padding-md);

    @include mq(screen-sm) {
      grid-template-columns: repeat(3, 1fr);
    }

}

.related-content .related-content-feed {
  background-color: var(--color-purple-light);
  
    &:before {
      content: "";

      position: absolute;
      top: 0;
      right: 100%;
      
      width: var(--padding-lg);
      height: 100%;

      background-color: var(--color-purple-light);

        @include mq(screen-xxl) {
          width: calc((var(--mq-xxl) - var(--mq-xl))/2); // The overspill needed to align with a max-width xxl container
        }

  }

}

.related-content-feed__item {
  display: flex;
  flex-direction: column;

    @include mq(screen-xs) {
      flex-direction: row;
    }

    @include mq(screen-sm) {
      flex-direction: column;
    }

}

.related-content-feed__img {
  position: relative;
  display: block;
  aspect-ratio: 4/3;

    @include mq(screen-xs) {
      width: 33.333%;
    }

    @include mq(screen-sm) {
      width: 100%;
      background-color: var(--color-purple-light-fade);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

}

.related-content-feed__text {
  position: relative;

  flex: 1;
  display: grid;
  gap: var(--padding-xs);
  align-content: start;

  padding: var(--padding-lg) var(--padding-md) var(--padding-xl);

  background: linear-gradient(0deg, rgba(120,110,176,0) 0%, rgba(120,110,176,0.1) 100%);

    @include mq(screen-sm) {
      padding: var(--padding-md) var(--padding-md) var(--padding-xl);
    }

    .title a {
      color: var(--color-blue-dark);
      text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

    }

    .text-btn {
      position: absolute;
      bottom: var(--padding-md);
      left: var(--padding-md);
      z-index: 1;
    }
    
}

.related-content-feed__text--no-link {
  padding: var(--padding-lg);

    @include mq(screen-sm) {
      padding: var(--padding-md);
    }
    
}

.related-content-feed__links {
  margin: 0 0 0 2rem;

  line-height: var(--line-height-md);
  list-style-type: disc;
}

.related-content-feed__link {
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);

  color: var(--color-blue-dark);

    &:hover {
      color: var(--color-purple);
    }
  
}

// Variants

.related-content-feed--team .related-content-feed__img {
  aspect-ratio: 1/1;
}

.related-content-feed--fixed {

  .related-content-feed__img {

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      width: 100%;
      height: 50%;

      background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
    }

    .tag {
      position: absolute;
      top: var(--padding-sm);
      left: var(--padding-md);
      z-index: 3;
    }
  
  }

}

// Multi-hover style

.related-content-feed__item.is-hovering {
  
  .title a {
    text-decoration: underline;
  }

}