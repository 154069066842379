.hero-blocks {
  display: grid;
  gap: var(--padding-sm);

    @include mq(screen-sm) {
      grid-template-columns: 1.5fr 1fr;
    }

}

.hero-block-img {
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 2/1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

}

.hero-block-text {
  line-height: var(--line-height-lg);

    @include mq(screen-lg) {
      font-size: var(--fs-fluid-md);
    }

}

.hero-block-link {
  display: flex;
  flex-direction: column;
  gap: var(--padding-md);
  padding: 0 var(--padding-lg) var(--padding-md);

  text-decoration: none;
  color: var(--color-blue-dark);
  letter-spacing: var(--minus-20);
  line-height: var(--line-height-sm);

  @extend .title;
  @extend .title--lg;

    @include mq(screen-sm) {
      padding: 0 var(--padding-md) var(--padding-lg);
    }

    @include mq(screen-lg) {
      flex-direction: row;
      gap: var(--padding-lg);
      align-items: center;

      padding: 0 var(--padding-md) var(--padding-lg);
    }

    svg {
      width: 2rem;
      height: 2rem;

      fill: currentColor;
      flex-shrink: 0;
    }

    &:hover {
      text-decoration: underline;

        svg {
          transform: translateX(0.25rem);
        }

    }

}

.hero-col-1 {

  .hero-block {
    display: flex;
    flex-direction: column;

      @include mq(screen-sm) {
        height: 100%;
      }
    
      .hero-text {
        order: 2;

          @include mq(screen-sm) {
            order: 1;
            flex: 1;
          }
        
          @include mq(screen-xl) {
            align-content: start;
            padding: var(--padding-lg) 30% var(--padding-xl) var(--padding-lg);
          }

      }

      .supporting-text {
        display: none;

          @include mq(screen-sm) {
            display: block;
          }

      }

      .hero-block-img {
        order: 1;

          @include mq(screen-sm) {
            order: 2;
          }

      }

  }

}

.hero-col-2 {
  display: grid;
  gap: var(--padding-sm);

    @include mq(screen-sm) {
      grid-template-rows: 1fr 1fr;
      //gap: 0;

      //background-color: var(--color-purple-light);
    }

    .hero-block {
      display: grid;
      justify-items: start;
      align-content: center;
      background-color: var(--color-purple-light);

        @include mq(screen-lg) {
          align-content: start;
        }


    }
  
    .hero-block-img {
      display: none;

        @include mq(screen-lg) {
          display: block;
        }

    }

    .tag {
      margin: var(--padding-lg) var(--padding-lg) var(--padding-sm);

        @include mq(screen-sm) {
          margin: var(--padding-md) var(--padding-md) var(--padding-sm);
        }
    }

}