.media-summary {
  position: relative;

  display: grid;

    @include mq(screen-md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-content: start;
    }

}

.media-summary-text {
  order: 2;

  position: relative;

  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: start;
  padding: var(--padding-lg);

  background-color: var(--color-purple-light);

    @include mq(screen-sm) {
      order: 1;

      width: 75%;

        &:after {
          content: "";

          position: absolute;
          top: 100%;
          left: 0;
          z-index: 2;

          width: 100%;
          height: 5rem;

          background-color: var(--color-purple-light);
        }
    }

    @include mq(screen-md) {
      width: auto;
      padding: var(--padding-xl) var(--padding-lg);

      &:after {
        top: 0;
        left: 100%;
        z-index: 2;

        width: 10%;
        height: 100%;
      }

    }

    .btn {

      @include mq(screen-md) {
        position: absolute;
        bottom: 0;
        left: var(--padding-lg);
        z-index: 1;
      }

    }
    
}

.media-summary-media {
  order: 1;
  
  @include mq(screen-sm) {
    order: 2;

    position: relative;
    z-index: 3;

    width: 50%;
    margin: 0 0 0 50%;
  }

  @include mq(screen-md) {
    width: auto;
    margin: 0;
  }

}

.media-summary-media__img {
  display: block;
  width: 100%;
  height: auto;
}

// Variants

.media-summary--featured .media-summary-text {
  
  @include mq(screen-md) {
    padding: var(--padding-lg) var(--padding-xl) var(--padding-xl);
  }

  .btn {
    
    @include mq(screen-md) {
      left: var(--padding-xl);
    }

  }

}

.media-summary--featured.is-hovering {
  
  .title a {
    text-decoration: underline;
  }

  .btn {
    text-decoration: underline;
    color: var(--color-lumo-green);

      svg {
        transform: translateX(0.15rem);
      }

  }

}