.mini-cta {
  padding: var(--padding-lg) var(--padding-lg) 0;
  background-color: var(--color-blue-dark);

    @include mq(screen-md) {
      padding: var(--padding-lg) var(--padding-xl) 0;
    }
}

.mini-cta-feed {
  position: relative;

  display: grid;
  gap: var(--padding-md);

    @include mq(screen-sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  
  
}

.mini-cta-feed__item {
  position: relative;
  display: grid;
  gap: var(--padding-xs);
  align-content: start;
  padding: var(--padding-md) var(--padding-md) var(--padding-xl);

  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 100%);

    .title a {
      color: white;
      text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
        
    }

    .text-btn {
      position: absolute;
      bottom: var(--padding-md);
      left: var(--padding-md);
      z-index: 1;
    }

}