.pg-footer-outer {
  margin: var(--padding-lg) 0 0;
  padding: var(--padding-lg) 0 var(--padding-xl);
  
  background: url('img/footer-topography.jpg') no-repeat bottom right;
  background-size: 35% auto;

  border-top: 1px solid var(--color-purple-light);
}

.pg-footer {
  display: grid;
  gap: var(--padding-lg);
}

.pg-footer-navs {
  display: grid;
  gap: var(--padding-lg);
  align-items: start;
  max-width: 75%;

    @include mq(screen-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-md) {
      grid-template-columns: repeat(4, 1fr);
    }
}

.pg-footer-nav {
  display: grid;
  gap: 1.5rem;
}

.pg-footer-nav__list {
  display: grid;
  align-items: start;
  gap: 1rem;
}

.pg-footer-nav__list a {
  font-size: var(--font-size-xs);
  color: var(--color-blue-dark);
  opacity: var(--hover-opacity);
  text-decoration: none;

    &:hover {
      opacity: 1;
      text-decoration: underline;
    }

}

.sign-off {
  display: grid;
  gap: var(--padding-md);
}

.sign-off__logo {
  display: block;
  width: 12rem;
  height: auto;
}
