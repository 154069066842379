.doc-grids {
  display: grid;
  gap: var(--padding-lg);
}

.doc-grid {
  display: grid;
  gap: var(--padding-sm);
  
    @include mq(screen-xs) {
      grid-template-columns: 1fr 1fr;
    }

}

.doc-grid__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-sm);
  height: 100%;
  padding: var(--padding-md);
  
  background-color: var(--color-blue-dark);

  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  text-align: center;
  color: white;
  text-decoration: none;

    &:hover {
      text-decoration: underline;
      
        svg {
          fill: white;
        }

    }

    svg {
      width: 5rem;
      height: 5rem;

      fill: var(--color-lumo-green);
    }

}