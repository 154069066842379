// =================================================
// LAYOUT STYLES
// =================================================

.main {
  overflow: hidden;
}

.outer {
  position: relative;
}

.inner {
  margin: 0 auto;
  padding: 0 var(--padding-md);
}

.inner--sm {
  max-width: var(--mq-sm);
}

.inner--md {
  max-width: var(--mq-md);
}

.inner--lg {
  max-width: var(--mq-lg);
}

.inner--xl {
  max-width: var(--mq-xl);
}

.inner--xxl {
  max-width: var(--mq-xxl);
}