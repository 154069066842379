.client-grid-outer {
  position: relative;
  padding: 0 4rem;

    .tns-outer {
      position: relative;
      z-index: 1;
    }
    
}

.client-grid__logo {
  display: block;
  width: 50%;
  height: 8rem;
  margin: 0 auto;

  object-fit: contain;
  object-position: center;

    @include mq(screen-lg) {
      width: 50%;
      height: 12rem;
    }

}

.client-grid-controls {
  //display: none;

    //@include mq(screen-lg) {
      position: absolute;
      top: calc(50% - 2rem);
      left: 0;
      z-index: 2;

      display: flex;
      justify-content: space-between;

      width: 100%;
    //}
    
}

.client-grid-control {
  width: 4rem;
  height: 4rem;
  padding: 1rem;

  background: none;
  border: none;

    svg {
      fill: var(--color-blue-dark);
    }

}

.client-grid-control--prev svg {
  transform: rotate(180deg);
}

// Tiny slider specifics

.client-grid-outer {
  position: relative;

    .tns-nav {  
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    
      width: 100%;
      height: 4rem;
      margin: var(--padding-sm) 0 0;

        @include mq(screen-lg) {
          display: none;
        }
        
    }
    
    .tns-nav button {
      display: block;
      width: 1rem;
      height: 1rem;
    
      border-radius: 100%;
      background-color: var(--color-blue-dark);
    
        &:hover {
          opacity: 0.5;
        }
    
        &.tns-nav-active {
          background-color: var(--color-lumo-green);
        }
    
    }

}

// Disabled

.client-grid-outer.is-disabled {

  .client-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 1rem;
  }

  .client-grid > div {
    width: 25rem;
  }
  
  .client-grid-controls {
    display: none;
  }

}