.services {
  position: relative;

    &:before {

      @include mq(screen-sm) {
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: calc(100% - var(--padding-lg));
        height: var(--padding-md);

        background-color: var(--color-blue-dark);
      }

      @include mq(screen-md) {
        width: calc(100% - var(--padding-xl));
      }

    }
  
    @include mq(screen-sm) {
      display: grid;
      //align-items: start;
      grid-template-columns: 1fr 1fr;
      padding: var(--padding-md) 0 0;
    }

}

.services-list {
  display: grid;
  gap: var(--padding-lg);
  padding: var(--padding-lg) var(--padding-lg) 0;
  background-color: var(--color-blue-dark);

    @include mq(screen-sm) {
      padding: var(--padding-lg) 0 0 var(--padding-lg);
    }

    @include mq(screen-md) {
      padding: var(--padding-xl) 0 0 var(--padding-xl);
    }

}

.services-list-intro {
  display: grid;
  gap: var(--padding-sm);
  place-items: start;

    @include mq(screen-sm) {
      padding: 0 var(--padding-lg) 0 0;
    }
}

.service-info-toggles {
  display: none;

    @include mq(screen-sm) {
      display: block;
    }

}

.service-info-links {
 
  @include mq(screen-sm) {
    display: none;
  }

}

.service-info-links {

  li:nth-child(odd) a {
    background-color: rgba(white, 0.1);
  }
  
  li:nth-child(even) a {
    background-color: rgba(white, 0.2);
  }

}

.service-info-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--padding-sm);

  font-size: var(--fs-fluid-md);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  color: white;
  text-decoration: none;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: currentColor;
    }

}

button.service-info-toggle {
  background-color: rgba(white, 0.1);

    &:hover {
      background-color: rgba(white, 0.2);
      text-decoration: underline;

        svg {
          transform: translateX(0.25rem);
        }

    }

}

button.service-info-toggle.is-active {
  background-color: var(--color-purple-light);

  color: var(--color-blue-dark);
}

.services-info {
  display: none;

    @include mq(screen-sm) {
      display: block;
      background-color: var(--color-purple-light);
    }
}

.service-info {
  display: none;

    &.is-visible {
      display: flex;
      flex-direction: column;
    }

}

.service-info-img {
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 16/9;

    img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

}

.service-info-text {
  position: relative;

  flex: 1;
  display: grid;
  gap: var(--padding-xs);
  align-content: start;

  padding: var(--padding-lg) var(--padding-lg) var(--padding-xl);

  background-color: var(--color-purple-light);

    // @include mq(screen-lg) {
    //   padding: var(--padding-lg) var(--padding-xl) var(--padding-xl);
    // }

    .btn {
      position: absolute;
      bottom: 0;
      left: var(--padding-lg);
      z-index: 1;
    }

}
