.pagination > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--padding-xs);
}

.pagination-pg {
  display: block;
  width: 2rem;
  height: 2rem;

  background: none;

  font-weight: var(--font-weight-bold);
  text-decoration: none;
  text-align: center;
  color: var(--color-blue-dark);

    &:hover {
      color: var(--color-purple);
    }

    &.mixitup-control-active {
      text-decoration: underline;
    }
}

.pagination-control {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;

  background: none;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: var(--color-blue-dark);
    }

}

.pagination-control--prev svg {
  transform: rotate(180deg);
}