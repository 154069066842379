.flex-img {
  @include flex-img;
}

.align-center {
  text-align: center;
}

.screen-reader-only {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.hide-dt {
  
  @include mq(screen-lg) {
    display: none;
  }

}