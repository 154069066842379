.features-overview-outer {
  overflow: hidden;
}

.features-overview {
  max-width: var(--mq-xl);
  margin: 0 auto;
  padding: 0 var(--padding-md);
  
    @include mq(screen-xxl) {
      padding: 0;
    }

}

.features-overview-intro {
  position: relative;

  background-color: var(--color-blue-dark);

    &:before {
      content: "";

      position: absolute;
      top: 0;
      right: 100%;
      
      width: var(--padding-lg);
      height: 100%;

      background-color: var(--color-blue-dark);

        @include mq(screen-xxl) {
          width: calc((var(--mq-xxl) - var(--mq-xl))/2); // The overspill needed to align with a max-width xxl container
        }

    }

}

.features-overview-intro__text {
  display: grid;
  gap: var(--padding-sm);
  max-width: var(--mq-xs);
  padding: var(--padding-lg) var(--padding-lg) var(--padding-lg) 0;

    @include mq(screen-sm) {
      padding: var(--padding-lg) 0;
    }
}

.features-overview-media {
  position: relative;

    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 100%;
      
      width: var(--padding-lg);
      height: 100%;

      background-color: var(--color-purple-light);

        @include mq(screen-xxl) {
          width: calc((var(--mq-xxl) - var(--mq-xl))/2); // The overspill needed to align with a max-width xxl container
        }

    }
  
    img {
      @include flex-img;
    }

}

.features-overview-grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  background-color: var(--color-purple-light);

    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 100%;
      
      width: var(--padding-lg);
      height: 100%;

      background-color: var(--color-purple-light);

        @include mq(screen-xxl) {
          width: calc((var(--mq-xxl) - var(--mq-xl))/2); // The overspill needed to align with a max-width xxl container
        }

    }

}

.features-overview-grid__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--padding-md);

  width: 100%;
  padding: var(--padding-lg);
    
    @include mq(screen-sm) {
      position: relative;
      width: 50%;
      padding: var(--padding-xl);
    }

    .summary-text {
      display: none;
    }

    .icon-btn-list {
      
      @include mq(screen-sm) {
        position: absolute;
        bottom: var(--padding-xl);
        left: var(--padding-xl);
        z-index: 1;
      }

    }

    .tag {
      overflow: hidden;
      min-width: 0;

        @include mq(screen-sm) {
          position: absolute;
          top: 3rem;
          left: var(--padding-xl);
          z-index: 1;
        }

        @include mq(screen-lg) {
          top: 4.5rem;
        }

    }

    .std-content ul {
      padding: 0;
      list-style-type: none;
    }

}

.features-overview-grid__item--link {
  
  @include mq(screen-sm) {
    padding: var(--padding-xl) var(--padding-xl) calc(var(--padding-xl)*2);
  }

}

.features-overview-grid__item:nth-child(4n + 2) {
  background-color: rgba(120,110,176,0.04);
}

.features-overview-grid__item:nth-child(4n + 3) {
  background-color: rgba(120,110,176,0.1);
}

.features-overview-grid__item:nth-child(4n + 4) {
  background-color: rgba(120,110,176,0.06);
}

.features-overview-grid__icon {
  height: 3rem;

  fill: var(--color-blue-dark);

    @include mq(screen-lg) {
      height: 5rem;
    }

}

.features-overview-grid__screenshot {
  display: block;
  width: 100%;
  height: auto;
}

// Layout variants

.features-overview-grid--odd {
  position: relative;
  
    .features-overview-grid__item:last-child {
      
      @include mq(screen-sm) {
        width: 100%;
        padding-right: 50%;
      }

      .summary-text {
        display: block;
      }

      .features-overview-grid__screenshot {

        @include mq(screen-sm) {
          position: absolute;
          top: var(--padding-xl);
          right: var(--padding-xl);
          z-index: 1;

          width: calc(50% - (var(--padding-xl)*2));
        }
        
      }

    }


}

.features-overview-grid--offices {

  &:before {
    display: none;
  }

  .features-overview-grid__item {
    
    @include mq(screen-sm) {
      padding: var(--padding-xl);
    }

  }

}

