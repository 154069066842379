.solutions {
  position: relative;

  background-color: var(--color-purple-light);

    @include mq(screen-sm) {
      padding: var(--padding-md) 0 0;
    }

    &:before {

      @include mq(screen-sm) {
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        
        width: var(--padding-lg);
        height: var(--padding-md);

        background-color: white;
      }

      @include mq(screen-xxl) {
        width: calc((var(--mq-xxl) - var(--mq-xl))/2); // The overspill needed to align with a max-width xxl container
      }

    }

    > div {
      width: 100%;
    }

}

.solution {
  
  @include mq(screen-sm) {
    display: grid;
    grid-template-columns: 50% 50%;
  }

}

.solution-text {
  position: relative;

  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: start;
  padding: var(--padding-lg);

  background-color: var(--color-purple-light);
    
    @include mq(screen-md) {
      padding: var(--padding-xl);
    }

    .btn {

      @include mq(screen-md) {
        position: absolute;
        bottom: 0;
        left: var(--padding-xl);
        z-index: 1;
      }

    }
    
}

.solution-img {
  aspect-ratio: 16/9;
  background-color: var(--color-blue-dark);
  
    @include mq(screen-sm) {
      aspect-ratio: auto;

      position: relative;
    }

    @include mq(screen-lg) {
      min-height: 50rem;
    }

}

.solution-img__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

    @include mq(screen-sm) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

}

.solutions .tns-nav {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  width: 100%;
  height: 4rem;

    @include mq(screen-sm) {
      width: 50%;
      height: var(--padding-md);

      background-color: var(--color-blue-dark);
    }

}

.solutions .tns-nav button {
  display: block;
  width: 1rem;
  height: 1rem;

  border-radius: 100%;
  background-color: var(--color-blue-dark);

    @include mq(screen-sm) {
      background-color: white;
    }

    &:hover {
      opacity: 0.5;
    }

    &.tns-nav-active {
      background-color: var(--color-lumo-green);
    }

}