.package-type-other,
.commodity-type-other {
  margin: 1rem 0 0;
}

.quote-form-actions,
.quiz-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-md);
  padding: var(--padding-md) 0 0;
}

.quote-form-markers,
.quiz-form-markers {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

    &:before {
      content: "";

      position: absolute;
      top: 2rem;
      left: calc(2rem - 2px);
      transform: translateY(-50%);
      z-index: 1;

      width: calc(100% - 4rem);
      height: 4px;
      background-color: var(--color-blue-light);
    }

}

.quote-form-marker,
.quiz-marker {
  position: relative;
  z-index: 1;
  display: block;

  width: 4rem;
  height: 4rem;
  
  line-height: 4rem;
  text-align: center;

  font-weight: var(--font-weight-bold);
  color: var(--color-blue-mid);

  background-color: var(--color-blue-light);
  border-radius: 100%;
}

.quote-form--step-1 .quote-form-marker--1,
.quote-form--step-2 .quote-form-marker--2,
.quote-form--step-3 .quote-form-marker--3,
.quiz-marker.current-marker {
  color: white;
  background-color: var(--color-blue-dark);
}

.quiz-options {
  display: grid;
  gap: var(--padding-sm);
}

.quiz-option {
  display: flex;
  width: 100%;
  gap: 1rem;
  padding: var(--padding-sm);

  border: 2px solid var(--color-blue-mid);
  background-color: white;

  font-family: 'Manrope', sans-serif;
  font-size: var(--fs-fluid-sm);
  font-weight: var(--font-weight-bold);

  cursor: pointer;

    &:hover {
      border-color: var(--color-blue-dark);
    }

}