.tag {
  display: inline-block;
  padding: 0.25rem 0.5rem;

  font-size: 1.4rem;
  font-weight: var(--font-weight-bold);
}

.tag--std {
  background-color: rgba(white, 0.1);

  color: white; 
}

.tag--featured {
  background-color: white;

  color: var(--color-blue-dark);
}

.tag--services {
  background-color: white;

  color: var(--color-services);
}

.tag--solutions {
  background-color: white;

  color: var(--color-solutions);
}

.tag--platforms {
  background-color: white;

  color: var(--color-platform);
}

.tag--ligentix {
  background-color: var(--color-lumo-green);

  color: var(--color-blue-dark);
}