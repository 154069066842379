.feed-hero {
  position: relative;
  z-index: 1;

  max-height: 50rem;
  padding: var(--padding-lg);
  background-color: var(--color-blue-dark);

    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      background: url(img/topography-gradient-1.png) no-repeat right -250px center;
      background-size: auto 500px;

      opacity: 0.15;
      filter: grayscale(1);

        @include mq(screen-sm) {
          opacity: 1;
          filter: grayscale(0);
        }

        @include mq(screen-lg) {
          background-size: auto 750px;
        }

    }

}

.feed-hero--1:before {
  background: url(img/topography-gradient-1.png) no-repeat right -250px center;
  background-size: auto 500px;
}

.feed-hero--2:before {
  background: url(img/topography-gradient-2.png) no-repeat right -250px center;
  background-size: auto 600px;
}

.feed-hero--3:before {
  background: url(img/topography-gradient-3.png) no-repeat right -250px center;
  background-size: auto 550px;
}

.feed-hero--4:before {
  background: url(img/topography-gradient-4.png) no-repeat right -250px center;
  background-size: auto 650px;
}

.feed-hero--vacancy:before {
  
  @include mq(screen-sm) {
    opacity: 0.25;
    filter: grayscale(1);
  }

}


.feed-hero-text {
  position: relative;
  z-index: 2;

  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: center;

    @include mq(screen-sm) {
      width: 50%;
    }

}

.post-meta {
  display: flex;
  align-items: center;
  gap: var(--padding-lg);
  flex-wrap: wrap;
  margin: var(--padding-md) 0 0;
}

.post-meta-author {
  display: flex;
  align-items: center;
  gap: var(--padding-xs);

    &:before {
      content: "";

      display: block;
      width: 3rem;
      height: 3rem;

      border-radius: 100%;
      background: linear-gradient(45deg, rgba(50,170,225,1) 0%, rgba(200,210,60,1) 100%);

        @include mq(screen-lg) {
          width: 5rem;
          height: 5rem;
        }
        
    }

}

.vacancy-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
  padding: 0;

  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  line-height: var(--line-height-md);
  color: white;

  list-style-type: disc;

    li {
      margin: 0 0 0 1.5rem;
      padding: 0 0 0 0.5rem;
    }

    li:first-child {
      margin: 0;
      padding: 0;
      
      &::marker {
        content: "";
      }

    }
}

// Variants

.feed-hero--vacancy .feed-hero-text {
  max-width: calc(var(--mq-sm) - (var(--padding-md)*2));
  margin: 0 auto;
}