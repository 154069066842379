.bio-intro {
  display: grid;
  grid-template-columns: 1.5fr 1fr;

  background-color: var(--color-purple-light);

  @extend .mtb-lg;

    @include mq(screen-sm) {
      grid-template-columns: 1fr 1fr;
    }

}

.bio-intro__text {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: var(--padding-md);
}