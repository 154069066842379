.testimonials {
  position: relative;
}

.testimonial {
  display: grid;
  gap: var(--padding-lg);
  
    @include mq(screen-sm) {
      align-items: center;
      grid-template-columns: 2fr 3fr;
    }

    @include mq(screen-lg) {
      padding: 0 var(--padding-xl);
    }

}

.testimonial-text {
  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: start;
}

.testimonial-img {
  
  @include mq(screen-xs) {
    display: grid;
    place-items: center;

    aspect-ratio: 1/1;

    border-top: 1px solid var(--color-lumo-green);
    border-right: 1px solid var(--color-lumo-green);
    border-left: 1px solid var(--color-lumo-green);
    border-bottom: 5px solid var(--color-lumo-green);
  }

}

.testimonial-img__img {
  display: block;
  width: auto;
  max-width: 12rem;
  max-height: 8rem;
  object-fit: contain;

    @include mq(screen-lg) {
      max-width: 18rem;
      max-height: 12rem;
    }

}

.testimonial-text__meta {
  color: var(--color-blue-dark);
  
    strong {
      font-weight: var(--font-weight-bold);
    }

}

.testimonials-controls {
  display: none;

    @include mq(screen-lg) {
      position: absolute;
      top: calc(50% - (4rem + var(--padding-sm))); // Deducting the height of the navl
      left: 0;
      z-index: 3;

      display: flex;
      justify-content: space-between;

      width: 100%;
    }
    
}

.testimonials-control {
  width: 4rem;
  height: 4rem;
  padding: 1rem;

  background: none;
  border: none;

    svg {
      fill: var(--color-blue-dark);
    }

}

.testimonials-control--prev svg {
  transform: rotate(180deg);
}

// Tiny slider specifics

.testimonials {
  position: relative;

    &:before,
    &:after {
     
      @include mq(screen-md) {
        content: "";

        position: absolute;
        top: 0;
        z-index: 2;

        width: 5rem;
        height: 100%;
      }

    }

    &:before {
      left: 0;
      background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }

    &:after {
      right: 0;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }

  .tns-nav {  
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  
    width: 100%;
    height: 4rem;
    margin: var(--padding-sm) 0 0;
  }
  
  .tns-nav button {
    display: block;
    width: 1rem;
    height: 1rem;
  
    border-radius: 100%;
    background-color: var(--color-blue-dark);
  
      &:hover {
        opacity: 0.5;
      }
  
      &.tns-nav-active {
        background-color: var(--color-lumo-green);
      }
  
  }

}