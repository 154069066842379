// =================================================
// STANDARD CONTENT STYLES
// =================================================

.rich-text {
	padding: 0 var(--padding-lg);

		@include mq(screen-lg) {
			padding: 0;
		}
		
}

.std-content {
	width: 100%;

	// & > *+* {
	// 	margin: var(--padding-sm) 0 0;
	// }

  & > *:not(h2, h3, h4, h5, h6)+* {
		margin: var(--padding-sm) 0 0;
	}
	
	// Main headings

	h2:not(:first-child), h3:not(:first-child), h4:not(:first-child), h5:not(:first-child), h6:not(:first-child) {
		margin: var(--padding-sm) 0 var(--padding-xs);
	}

  h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
		margin: 0 0 var(--padding-xs);
	}
	
	// Main headings

	h2, h3, h4, h5 {
		color: var(--color-blue-dark);
		font-weight: var(--font-weight-bold);
		line-height: var(--line-height-sm);
	}

  h2 {
    font-size: var(--fs-fluid-lg);
  }

  h3 {
    font-size: var(--fs-fluid-md);
  }

  h4 {
    font-size: var(--fs-fluid-sm);
  }

  h5 {
    font-size: var(--fs-fluid-sm);
  }


	// Paragraphs

	p,ul,ol {

		a {
			color: var(--color-blue-dark);
			text-decoration: underline;
		}

		a:hover {
      font-weight: var(--font-weight-bold);
			color: var(--color-platform);
		}

		& > strong {
      font-weight: var(--font-weight-bold);
			color: var(--color-blue-dark);
		}

		& > em {
			font-style: italic;
		}
					
	}

	p {
		line-height: var(--line-height-lg);
	}

	sup {
		font-size: 75%;
	}

	ul, ol {

	}

	ul {
		list-style-type: disc;
		padding-left: var(--padding-sm);
	}

	ol {
		list-style-type: decimal;
		padding-left: var(--padding-sm);
		counter-reset: item;
	}

	ul ul {
		margin: 1rem 0 0 3rem;
		list-style-type: circle;
	}

	li {		
		line-height: var(--line-height-lg);
	}

	li + li {
		margin-top: 1rem;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
		margin: var(--padding-md) auto;
	}

	a {
    @extend .text-btn;
		@extend .text-btn--blue-dark;
	}

	blockquote {
		display: block;
		padding: var(--padding-md);
		margin: var(--padding-lg) 0;

		border-left: 3px solid var(--color-lumo-green);

			p {
				font-size: var(--fs-fluid-xl);
				font-weight: var(--font-weight-bold);
				color: var(--color-blue-dark);
				line-height: var(--line-height-md);
				letter-spacing: var(--minus-20);

					&:before {
						content: open-quote;
					}

					&:after {
						content: close-quote;
					}

			}

	}

	address {
		font-style: normal;
	}


}

// WP overrides

.std-content {

	img.alignleft,
	img.alignright {
		width: 50%;
		margin: var(--padding-md) auto;

			@include mq(screen-xs) {
				width: 20%;
				clear: both;
				margin-top: 0.5rem;
				margin-bottom: var(--padding-sm);
			}

			@include mq(screen-lg) {
				width: 25%;
			}

	}

	img.alignleft {
		
		@include mq(screen-xs) {
			float: left;
			margin-right: var(--padding-md);
		}

	}

	img.alignright {
		
		@include mq(screen-xs) {
			float: right;
			margin-left: var(--padding-md);
		}

	}

	img.alignnone {
		clear: both;
	}

	.wp-caption {
		width: 100% !important;
		height: auto;
		margin: var(--padding-md) auto;


			img {
				margin: 0;
			}
			
	}

	p.wp-caption-text {
		margin: 0 auto;
		padding: 1rem;

		font-size: var(--fs-fluid-xs);
		line-height: var(--line-height-sm);
		text-align: center;
    color: var(--color-blue-dark);

		background-color: var(--color-blue-light);
	}

}

.std-content iframe,
.video-embed iframe {
  width: 100%;
  height: 100%;

  aspect-ratio: 16 / 9;
}

.std-content table {
	
}

// Min-height variants

.single-glossary .std-content {
	min-height: 50vh;
}

.plain-msg {
	min-height: 50vh;
}

// Embedded cookies policy

#ot-sdk-cookie-policy,
.ot-sdk-cookie-policy {
	font-size: var(--fs-fluid-sm) !important;
}