.responsive-table {
  position: relative;
  //width: 100%;
  // overflow-x: scroll;
}

.responsive-table table {
  border-collapse: collapse;
  border-spacing: 0px;

  background-color: var(--color-purple-light);

  display: inline-block;
  vertical-align: top;
  //width: 100%;
  // white-space: nowrap;

    tr:not(:last-child) td {
      border-bottom: 1px solid white;
    }

    th {
      font-weight: var(--font-weight-bold);
      border-bottom: 3px solid var(--color-purple-light-fade);
    }

    th,td {
      padding: 1.5rem;

      font-size: var(--fs-fluid-xs);
      vertical-align: top;
      text-align: left;

      // max-width:100%;
      // white-space:nowrap;
    }


}

.responsive-table__helper {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0 0 1rem;
}