.vacancies-list {
}

.vacancies-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--padding-md);
}

.vacancies-filter {
  display: flex;
  gap: 2rem;
  align-items: center;

    select {
      display: block;
      height: 5rem;
      padding: 0 3rem 0 1rem;

      border: 2px solid var(--color-blue-mid);
      background-color: white;

      font-family: 'Manrope', sans-serif;
      font-size: var(--fs-fluid-sm);

      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 2rem;

        &:focus {
          outline: none;
          border-color: var(--color-blue-dark);
        }

    }

}

.vacancies-outer {
  padding: 0 0 4rem;

    @media (max-width: $screen-sm) {
      overflow-x: scroll;
    }
    
}

.vacancies-list {
  width: 100%;
  border-collapse: collapse;

    @media (max-width: $screen-sm) {
      width: var(--mq-lg);
    }

    th, td {
      border: 0;
    }
    
    th {
      width: 30%;
      padding: 1rem;

      font-weight: var(--font-weight-bold);
      color: var(--color-blue-dark);
    }

    td {
      vertical-align: top;
      padding: 2rem 1rem 0;

      line-height: var(--line-height-md);

        @include mq(screen-sm) {
          padding: 4rem 1rem 0;
        }
    }

}

.vacancies-list__header {
  background-color: var(--color-purple-light);
}

.vacancies-list__link {
  width: 10%;
}

.vacancies-helper {
  @include mq(screen-sm) {
    display: none;
  }
}

