/*!
Theme Name: Ligentia
Theme URI: 
Author: Ride Shotgun
Author URI: http://www.rideshotgun.co.uk
Description: 
Version: 2.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/
:root {
  --fluid-min-width: 320;
  --fluid-max-width: 1600;
  --fluid-screen: 100vw;
  --fluid-bp: calc((var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) / (var(--fluid-max-width) - var(--fluid-min-width)));
  --f-0-min: 18;
  --f-0-max: 21;
  --fs-fluid-xs: calc(
    ((var(--f-0-min) / 16) * 1rem) + (var(--f-0-max) - var(--f-0-min)) *
      var(--fluid-bp)
  );
  --f-1-min: 21;
  --f-1-max: 24;
  --fs-fluid-sm: calc(
    ((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) *
      var(--fluid-bp)
  );
  --f-2-min: 24;
  --f-2-max: 30;
  --fs-fluid-md: calc(
    ((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) *
      var(--fluid-bp)
  );
  --f-3-min: 27;
  --f-3-max: 36;
  --fs-fluid-lg: calc(
    ((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) *
      var(--fluid-bp)
  );
  --f-4-min: 30;
  --f-4-max: 45;
  --fs-fluid-xl: calc(
    ((var(--f-4-min) / 16) * 1rem) + (var(--f-4-max) - var(--f-4-min)) *
      var(--fluid-bp)
  );
  --f-5-min: 33;
  --f-5-max: 54;
  --fs-fluid-xxl: calc(
    ((var(--f-5-min) / 16) * 1rem) + (var(--f-5-max) - var(--f-5-min)) *
      var(--fluid-bp)
  );
  --f-6-min: 40.00;
  --f-6-max: 95.00;
  --fs-fluid-max: calc(
    ((var(--f-6-min) / 16) * 1rem) + (var(--f-6-max) - var(--f-6-min)) *
      var(--fluid-bp)
  );
  --padding-xl-min: 60;
  --padding-xl-max: 120;
  --padding-xl: calc(((var(--padding-xl-min) / 16) * 1rem) + (var(--padding-xl-max) - var(--padding-xl-min)) * var(--fluid-bp));
  --padding-lg-min: 40;
  --padding-lg-max: 80;
  --padding-lg: calc(((var(--padding-lg-min) / 16) * 1rem) + (var(--padding-lg-max) - var(--padding-lg-min)) * var(--fluid-bp));
  --padding-md-min: 20;
  --padding-md-max: 40;
  --padding-md: calc(((var(--padding-md-min) / 16) * 1rem) + (var(--padding-md-max) - var(--padding-md-min)) * var(--fluid-bp));
  --padding-sm-min: 15;
  --padding-sm-max: 30;
  --padding-sm: calc(((var(--padding-sm-min) / 16) * 1rem) + (var(--padding-sm-max) - var(--padding-sm-min)) * var(--fluid-bp));
  --padding-xs-min: 10;
  --padding-xs-max: 20;
  --padding-xs: calc(((var(--padding-xs-min) / 16) * 1rem) + (var(--padding-xs-max) - var(--padding-xs-min)) * var(--fluid-bp));
  --font-weight-bold: 700;
  --line-height-sm: 1.25;
  --line-height-md: 1.5;
  --line-height-lg: 1.75;
  --color-blue-dark: #053345;
  --color-blue-type: rgba(5, 51, 69, 0.8);
  --color-blue-mid: #7ec0ce;
  --color-blue-light: #f2f9fa;
  --color-blue-light-fade: rgba(126,192,206,0.1);
  --color-lumo-green: #89ffbc;
  --color-purple: #786eb0;
  --color-purple-light: #f1f0f7;
  --color-purple-light-fade: rgba(120,110,176,0.1);
  --color-pink-light: #FDE9F1;
  --color-platform: #b40089;
  --color-solutions: #7832b0;
  --color-services: #336ee0;
  --color-white-type: rgba(255,255,255, 0.8);
  --color-error: #C60000;
  --color-success: #90B327;
  --minus-20: -0.02em;
  --minus-50: -0.05em;
  --hover-opacity: 0.8;
  --mq-xs: 640px;
  --mq-sm: 768px;
  --mq-md: 1024px;
  --mq-lg: 1280px;
  --mq-xl: 1536px;
  --mq-xxl: 1800px;
}

@media screen and (min-width: 1280px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}
html {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif;
  font-size: var(--fs-fluid-sm);
  font-weight: 400;
  font-style: normal;
  color: var(--color-blue-type);
  letter-spacing: var(--minus-20);
  background-color: white;
}

[id] {
  scroll-margin-top: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: var(--minus-20);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
big,
cite,
code,
em,
font,
img,
small,
strike,
sub,
sup,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tr,
th,
td {
  border: none;
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
}

strong {
  font-weight: normal;
}

blockquote::before,
blockquote::after {
  content: "";
}

html {
  font-size: 62.5%; /* 1rem = 10px */
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0s !important;
    transition-duration: 0s !important;
  }
}
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=date],
input[type=month],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=week],
input[type=number],
input[type=search],
input[type=tel],
input[type=color],
input[type=submit],
textarea,
button {
  font-family: "Manrope", sans-serif;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}

select {
  font-size: inherit;
}

button {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.screen-reader-text {
  display: none;
}

ul, ol {
  list-style-type: none;
}

.flex-img {
  display: block;
  width: 100%;
  height: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .flex-img {
    flex-shrink: 0;
  }
}

.align-center {
  text-align: center;
}

.screen-reader-only {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

@media (min-width: 1280px) {
  .hide-dt {
    display: none;
  }
}

.main {
  overflow: hidden;
}

.outer {
  position: relative;
}

.inner {
  margin: 0 auto;
  padding: 0 var(--padding-md);
}

.inner--sm {
  max-width: var(--mq-sm);
}

.inner--md {
  max-width: var(--mq-md);
}

.inner--lg, .text-img {
  max-width: var(--mq-lg);
}

.inner--xl {
  max-width: var(--mq-xl);
}

.inner--xxl {
  max-width: var(--mq-xxl);
}

.mt-xs {
  margin-top: var(--padding-xs);
}

.mt-sm {
  margin-top: var(--padding-sm);
}

.mt-md {
  margin-top: var(--padding-md);
}

.mt-lg {
  margin-top: var(--padding-lg);
}

.mt-xl {
  margin-top: var(--padding-xl);
}

.mb-xs {
  margin-bottom: var(--padding-xs);
}

.mb-sm {
  margin-bottom: var(--padding-sm);
}

.mb-md {
  margin-bottom: var(--padding-md);
}

.mb-lg {
  margin-bottom: var(--padding-lg);
}

.mb-xl {
  margin-bottom: var(--padding-xl);
}

.mtb-xs {
  margin: var(--padding-xs) 0;
}

.mtb-sm {
  margin: var(--padding-sm) 0;
}

.mtb-md {
  margin: var(--padding-md) 0;
}

.mtb-lg, .bio-intro {
  margin: var(--padding-lg) 0;
}

.mtb-xl {
  margin: var(--padding-xl) 0;
}

.m-xs {
  margin: var(--padding-xs);
}

.m-sm {
  margin: var(--padding-sm);
}

.m-md {
  margin: var(--padding-md);
}

.m-lg {
  margin: var(--padding-lg);
}

.m-xl {
  margin: var(--padding-xl);
}

.pt-xs {
  padding-top: var(--padding-xs);
}

.pt-sm {
  padding-top: var(--padding-sm);
}

.pt-md {
  padding-top: var(--padding-md);
}

.pt-lg {
  padding-top: var(--padding-lg);
}

.pt-xl {
  padding-top: var(--padding-xl);
}

.pb-xs {
  padding-bottom: var(--padding-xs);
}

.pb-sm {
  padding-bottom: var(--padding-sm);
}

.pb-md {
  padding-bottom: var(--padding-md);
}

.pb-lg {
  padding-bottom: var(--padding-lg);
}

.pb-xl {
  padding-bottom: var(--padding-xl);
}

.ptb-xs {
  padding: var(--padding-xs) 0;
}

.ptb-sm {
  padding: var(--padding-sm) 0;
}

.ptb-md {
  padding: var(--padding-md) 0;
}

.ptb-lg {
  padding: var(--padding-lg) 0;
}

.ptb-xl {
  padding: var(--padding-xl) 0;
}

.p-xs {
  padding: var(--padding-xs);
}

.p-sm {
  padding: var(--padding-sm);
}

.p-md {
  padding: var(--padding-md);
}

.p-lg {
  padding: var(--padding-lg);
}

.p-xl {
  padding: var(--padding-xl);
}

.pg-header-outer {
  padding: 0 var(--padding-md);
}

.eyebrow-nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  height: 4rem;
  padding: 0 0 0 var(--padding-sm);
  background-color: var(--color-purple-light);
}
@media (min-width: 768px) {
  .eyebrow-nav {
    justify-content: flex-end;
    gap: var(--padding-md);
  }
}

.eyebrow-nav__list {
  display: flex;
  gap: 1rem;
}
.eyebrow-nav__list a {
  font-size: var(--fs-fluid-sm);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  text-decoration: none;
  color: var(--color-blue-dark);
  opacity: var(--hover-opacity);
}
@media (min-width: 768px) {
  .eyebrow-nav__list a {
    padding: 0 2.5rem 0 0;
    background: url("svg/ext-link-blue-dark.svg") no-repeat right center;
    background-size: 1.5rem 1.5rem;
  }
}
.eyebrow-nav__list a:hover {
  text-decoration: underline;
  opacity: 1;
}
@media (min-width: 768px) {
  .eyebrow-nav__list {
    gap: var(--padding-md);
  }
}

.toggle-language-switcher {
  display: flex;
  align-items: center;
  height: 4rem;
  gap: 1rem;
  padding: 0 1rem;
  font-size: var(--fs-fluid-sm);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  text-decoration: none;
  color: var(--color-blue-dark);
  line-height: 4rem;
  opacity: var(--hover-opacity);
}
.toggle-language-switcher:hover, .toggle-language-switcher.is-active {
  background-color: var(--color-blue-dark);
  color: white;
  opacity: 1;
}

.toggle-language-switcher__flag {
  display: block;
}
@media (min-width: 768px) {
  .toggle-language-switcher__flag {
    display: none;
  }
}
.toggle-language-switcher__flag svg {
  width: 2.4rem;
}

.toggle-language-switcher__label {
  display: none;
}
@media (min-width: 768px) {
  .toggle-language-switcher__label {
    display: block;
  }
}

.toggle-language-switcher__arrow {
  display: block;
}
.toggle-language-switcher__arrow svg {
  width: 1rem;
  height: 1rem;
  transform: rotate(90deg);
  fill: currentColor;
}

.language-switcher {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  display: none;
  align-content: start;
  gap: 1rem;
  padding: 1rem;
  width: 20rem;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
}
@media (min-width: 768px) {
  .language-switcher {
    width: 30rem;
  }
}
.language-switcher.is-open {
  display: grid;
}

.language-switcher-list {
  display: grid;
  gap: 1rem;
}

.switcher-list__hide {
  display: none;
}

.switcher-list__radio {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  font-weight: var(--font-weight-bold);
  text-decoration: none;
  color: var(--color-blue-dark);
}
.switcher-list__radio:hover .switcher-list__label {
  text-decoration: underline;
}

.switcher-list__radio--selected {
  background-color: var(--color-lumo-green);
}
.switcher-list__radio--selected .switcher-list__check {
  display: grid;
}

.switcher-list__flag {
  width: 2.4rem;
}
.switcher-list__flag svg {
  width: 100%;
}

.switcher-list__check {
  display: none;
  place-items: center;
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
}
.switcher-list__check svg {
  width: 1rem;
  height: 1rem;
  fill: var(--color-blue-dark);
}

.pg-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-md) 0;
}

.pg-header--with-border {
  margin: 0 0 var(--padding-md);
  border-bottom: 1px solid var(--color-purple-light);
}

.pg-header-logo {
  display: block;
  width: 12rem;
}
@media (min-width: 1280px) {
  .pg-header-logo {
    width: 16rem;
  }
}
.pg-header-logo img {
  display: block;
  width: 100%;
  height: auto;
}

.main-nav-toggle {
  display: flex;
  gap: 1rem;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.8rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-dark);
}
.main-nav-toggle svg {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}
@media (min-width: 1280px) {
  .main-nav-toggle {
    display: none;
  }
}

.main-nav-outer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  padding: 6rem 0 0;
  overflow-y: scroll;
  background-color: var(--color-purple-light);
}
.main-nav-outer.is-open {
  display: block;
}
@media (min-width: 1280px) {
  .main-nav-outer {
    position: static;
    display: block;
    width: auto;
    height: auto;
    padding: 0;
    background: none;
    box-shadow: none;
    overflow-y: visible;
  }
}

.main-nav-close {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding: 0 2rem;
  font-size: 2.4rem;
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  color: var(--color-blue-dark);
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
}
.main-nav-close svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
}
@media (min-width: 1280px) {
  .main-nav-close {
    display: none;
  }
}

.main-nav {
  padding: 2rem;
}
@media (min-width: 1280px) {
  .main-nav {
    padding: 0;
  }
}

.main-nav__list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media (min-width: 1280px) {
  .main-nav__list {
    flex-direction: row;
    gap: 0;
  }
}

.pg-header-btn {
  display: none;
}
@media (min-width: 1280px) {
  .pg-header-btn {
    display: block;
    padding: 1rem 2rem;
    background-color: var(--color-lumo-green);
    font-size: 1.8rem;
    font-weight: var(--font-weight-bold);
    color: var(--color-blue-dark);
    letter-spacing: var(--minus-20);
    text-decoration: none;
  }
  .pg-header-btn:hover {
    background-color: var(--color-blue-dark);
    color: white;
  }
}

.main-nav__list a {
  text-decoration: none;
}

.main-nav__list li a:not([href="#"]) {
  font-size: 1.8rem;
  color: var(--color-blue-dark);
  opacity: var(--hover-opacity);
}
.main-nav__list li a:not([href="#"]):hover {
  opacity: 1;
  text-decoration: underline;
}

.main-nav__list li.parent > a {
  display: block;
  font-size: 2.4rem;
  color: var(--color-blue-dark);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
}
@media (min-width: 1280px) {
  .main-nav__list li.parent > a {
    padding: 1rem 2rem;
    font-size: 1.8rem;
  }
  .main-nav__list li.parent > a:hover {
    text-decoration: underline;
  }
}
@media (min-width: 1536px) {
  .main-nav__list li.parent > a {
    padding: 1rem 3rem;
  }
}

.main-nav__list li.parent > a[href="#"]:after {
  content: "";
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  transform: translateY(-50%);
  z-index: 1;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: var(--color-blue-dark) transparent transparent transparent;
}
@media (min-width: 1280px) {
  .main-nav__list li.parent > a[href="#"]:after {
    display: none;
  }
}

.main-nav__list li > .sub-menu.is-open {
  margin: 2rem 0 0;
  padding: 0 0 0 3rem;
  border-left: 2px solid var(--color-blue-dark);
}
@media (min-width: 1280px) {
  .main-nav__list li > .sub-menu.is-open {
    margin: 0;
    padding: 0;
    border: none;
  }
}

.main-nav__list li > .sub-menu li:not(.solutions-sub-title) + li:not(.solutions-sub-title),
.main-nav__list li > .sub-menu .sub-menu li:not(.solutions-sub-title) + li:not(.solutions-sub-title) {
  margin: 1.5rem 0 0;
}

.main-nav__list a[href=""] + .sub-menu {
  display: grid;
  gap: 1.5rem;
}

.main-nav__list > li {
  position: relative;
}

.main-nav__list li.parent > .sub-menu {
  display: none;
}
@media (min-width: 1280px) {
  .main-nav__list li.parent > .sub-menu {
    padding: 3rem;
    background-color: var(--color-purple-light);
  }
}

.main-nav__list li.parent > .sub-menu.is-open {
  display: grid;
}
@media (min-width: 1280px) {
  .main-nav__list li.parent > .sub-menu.is-open {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
  }
}

@media (min-width: 1280px) {
  .main-nav__list > li:not(.parent--solutions) > .sub-menu {
    width: 30rem;
  }
}

.main-nav__list > li.parent--solutions > .sub-menu {
  gap: 2rem;
}
@media (min-width: 1280px) {
  .main-nav__list > li.parent--solutions > .sub-menu {
    margin: 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    width: 70rem;
  }
}
@media (min-width: 1536px) {
  .main-nav__list > li.parent--solutions > .sub-menu {
    width: 90rem;
  }
}

@media (min-width: 1280px) {
  .main-nav__list > li.parent > a.is-active {
    background-color: var(--color-purple-light);
  }
}

@media (min-width: 1280px) {
  .main-nav__list > li.parent--platform > .sub-menu {
    border-bottom: 5px solid var(--color-platform);
  }
}

@media (min-width: 1280px) {
  .main-nav__list > li.parent--solutions > .sub-menu {
    border-bottom: 5px solid var(--color-solutions);
  }
}

@media (min-width: 1280px) {
  .main-nav__list > li.parent--services > .sub-menu {
    border-bottom: 5px solid var(--color-services);
  }
}

@media (min-width: 1280px) {
  .main-nav__list li.parent--resources > .sub-menu,
  .main-nav__list li.parent--about > .sub-menu {
    border-bottom: 5px solid var(--color-lumo-green);
  }
}

.main-nav__list li.solutions-sub-title > a {
  display: block;
  margin: 0 0 0.5rem;
  font-size: 1.8rem;
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  color: var(--color-blue-dark);
  cursor: text;
}
@media (min-width: 1280px) {
  .main-nav__list li.solutions-sub-title > a {
    margin: 0 0 1.5rem;
  }
  .main-nav__list li.solutions-sub-title > a:focus {
    color: var(--color-blue-dark);
  }
  .main-nav__list li.solutions-sub-title > a:visited {
    color: var(--color-blue-dark);
  }
}

.pg-footer-outer {
  margin: var(--padding-lg) 0 0;
  padding: var(--padding-lg) 0 var(--padding-xl);
  background: url("img/footer-topography.jpg") no-repeat bottom right;
  background-size: 35% auto;
  border-top: 1px solid var(--color-purple-light);
}

.pg-footer {
  display: grid;
  gap: var(--padding-lg);
}

.pg-footer-navs {
  display: grid;
  gap: var(--padding-lg);
  align-items: start;
  max-width: 75%;
}
@media (min-width: 768px) {
  .pg-footer-navs {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .pg-footer-navs {
    grid-template-columns: repeat(4, 1fr);
  }
}

.pg-footer-nav {
  display: grid;
  gap: 1.5rem;
}

.pg-footer-nav__list {
  display: grid;
  align-items: start;
  gap: 1rem;
}

.pg-footer-nav__list a {
  font-size: var(--font-size-xs);
  color: var(--color-blue-dark);
  opacity: var(--hover-opacity);
  text-decoration: none;
}
.pg-footer-nav__list a:hover {
  opacity: 1;
  text-decoration: underline;
}

.sign-off {
  display: grid;
  gap: var(--padding-md);
}

.sign-off__logo {
  display: block;
  width: 12rem;
  height: auto;
}

.title, .hero-block-link {
  font-weight: var(--font-weight-bold);
}
.title a, .hero-block-link a {
  color: inherit;
  text-decoration: none;
}
.title a:hover, .hero-block-link a:hover {
  text-decoration: underline;
}

.title--xxl {
  font-size: var(--fs-fluid-xxl);
  line-height: var(--line-height-sm);
}

.title--xl {
  font-size: var(--fs-fluid-xl);
  line-height: var(--line-height-sm);
}

.title--lg, .hero-block-link {
  font-size: var(--fs-fluid-lg);
  line-height: var(--line-height-sm);
}

.title--md {
  font-size: var(--fs-fluid-md);
  line-height: var(--line-height-md);
}

.title--sm {
  font-size: var(--fs-fluid-sm);
  line-height: var(--line-height-lg);
}

.summary-text {
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  line-height: var(--line-height-lg);
}

.supporting-text {
  line-height: var(--line-height-lg);
}

.text-white {
  color: white;
}

.text-white-fade {
  color: var(--color-white-type);
}

.text-blue-dark {
  color: var(--color-blue-dark);
}

.text-blue-mid {
  color: var(--color-blue-mid);
}

.text-lumo-green {
  color: var(--color-lumo-green);
}

.btn, .tml-button, .form-row input[type=submit],
.hs-submit input[type=submit] {
  display: inline-flex;
  gap: 1.5rem;
  align-items: center;
  min-height: 3.5rem;
  padding: 0.5rem 1.5rem;
  font-family: "Manrope", sans-serif;
  font-size: var(--fs-fluid-sm);
  font-weight: var(--font-weight-bold);
  text-decoration: none;
  cursor: pointer;
  border: none;
}
.btn svg, .tml-button svg, .form-row input[type=submit] svg,
.hs-submit input[type=submit] svg {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
}
.btn:hover, .tml-button:hover, .form-row input[type=submit]:hover,
.hs-submit input[type=submit]:hover {
  text-decoration: underline;
}
.btn:hover svg, .tml-button:hover svg, .form-row input[type=submit]:hover svg,
.hs-submit input[type=submit]:hover svg {
  transform: translateX(0.15rem);
}

.btn--blue-dark, .tml-button, .form-row input[type=submit],
.hs-submit input[type=submit] {
  color: white;
  background-color: var(--color-blue-dark);
}
.btn--blue-dark:hover, .tml-button:hover, .form-row input[type=submit]:hover,
.hs-submit input[type=submit]:hover {
  color: var(--color-lumo-green);
}

.btn--lumo-green {
  color: var(--color-blue-dark);
  background-color: var(--color-lumo-green);
}

.text-btn, .tml-links a, .std-content a {
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  cursor: pointer;
}

button.text-btn,
input.text-btn {
  border: none;
  background: none;
  text-decoration: underline;
}

.text-btn--icon {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.text-btn--icon svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
}

.text-btn--blue-dark, .tml-links a, .std-content a {
  color: var(--color-blue-dark);
}
.text-btn--blue-dark:hover, .tml-links a:hover, .std-content a:hover {
  color: var(--color-purple);
}

.text-btn--lumo-green {
  color: var(--color-lumo-green);
}
.text-btn--lumo-green:hover {
  color: white;
}

.icon-btn {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  text-decoration: none;
  cursor: pointer;
}
.icon-btn:hover {
  text-decoration: underline;
}
.icon-btn svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
}

.icon-btn--blue-dark {
  color: var(--color-blue-dark);
}

.icon-btn-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-sm) var(--padding-lg);
}

.rich-text {
  padding: 0 var(--padding-lg);
}
@media (min-width: 1280px) {
  .rich-text {
    padding: 0;
  }
}

.std-content {
  width: 100%;
}
.std-content > *:not(h2, h3, h4, h5, h6) + * {
  margin: var(--padding-sm) 0 0;
}
.std-content h2:not(:first-child), .std-content h3:not(:first-child), .std-content h4:not(:first-child), .std-content h5:not(:first-child), .std-content h6:not(:first-child) {
  margin: var(--padding-sm) 0 var(--padding-xs);
}
.std-content h2:first-child, .std-content h3:first-child, .std-content h4:first-child, .std-content h5:first-child, .std-content h6:first-child {
  margin: 0 0 var(--padding-xs);
}
.std-content h2, .std-content h3, .std-content h4, .std-content h5 {
  color: var(--color-blue-dark);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-sm);
}
.std-content h2 {
  font-size: var(--fs-fluid-lg);
}
.std-content h3 {
  font-size: var(--fs-fluid-md);
}
.std-content h4 {
  font-size: var(--fs-fluid-sm);
}
.std-content h5 {
  font-size: var(--fs-fluid-sm);
}
.std-content p a, .std-content ul a, .std-content ol a {
  color: var(--color-blue-dark);
  text-decoration: underline;
}
.std-content p a:hover, .std-content ul a:hover, .std-content ol a:hover {
  font-weight: var(--font-weight-bold);
  color: var(--color-platform);
}
.std-content p > strong, .std-content ul > strong, .std-content ol > strong {
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-dark);
}
.std-content p > em, .std-content ul > em, .std-content ol > em {
  font-style: italic;
}
.std-content p {
  line-height: var(--line-height-lg);
}
.std-content sup {
  font-size: 75%;
}
.std-content ul {
  list-style-type: disc;
  padding-left: var(--padding-sm);
}
.std-content ol {
  list-style-type: decimal;
  padding-left: var(--padding-sm);
  counter-reset: item;
}
.std-content ul ul {
  margin: 1rem 0 0 3rem;
  list-style-type: circle;
}
.std-content li {
  line-height: var(--line-height-lg);
}
.std-content li + li {
  margin-top: 1rem;
}
.std-content img {
  display: block;
  width: 100%;
  height: auto;
  margin: var(--padding-md) auto;
}
.std-content blockquote {
  display: block;
  padding: var(--padding-md);
  margin: var(--padding-lg) 0;
  border-left: 3px solid var(--color-lumo-green);
}
.std-content blockquote p {
  font-size: var(--fs-fluid-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-dark);
  line-height: var(--line-height-md);
  letter-spacing: var(--minus-20);
}
.std-content blockquote p:before {
  content: open-quote;
}
.std-content blockquote p:after {
  content: close-quote;
}
.std-content address {
  font-style: normal;
}

.std-content img.alignleft,
.std-content img.alignright {
  width: 50%;
  margin: var(--padding-md) auto;
}
@media (min-width: 640px) {
  .std-content img.alignleft,
  .std-content img.alignright {
    width: 20%;
    clear: both;
    margin-top: 0.5rem;
    margin-bottom: var(--padding-sm);
  }
}
@media (min-width: 1280px) {
  .std-content img.alignleft,
  .std-content img.alignright {
    width: 25%;
  }
}
@media (min-width: 640px) {
  .std-content img.alignleft {
    float: left;
    margin-right: var(--padding-md);
  }
}
@media (min-width: 640px) {
  .std-content img.alignright {
    float: right;
    margin-left: var(--padding-md);
  }
}
.std-content img.alignnone {
  clear: both;
}
.std-content .wp-caption {
  width: 100% !important;
  height: auto;
  margin: var(--padding-md) auto;
}
.std-content .wp-caption img {
  margin: 0;
}
.std-content p.wp-caption-text {
  margin: 0 auto;
  padding: 1rem;
  font-size: var(--fs-fluid-xs);
  line-height: var(--line-height-sm);
  text-align: center;
  color: var(--color-blue-dark);
  background-color: var(--color-blue-light);
}

.std-content iframe,
.video-embed iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}

.single-glossary .std-content {
  min-height: 50vh;
}

.plain-msg {
  min-height: 50vh;
}

#ot-sdk-cookie-policy,
.ot-sdk-cookie-policy {
  font-size: var(--fs-fluid-sm) !important;
}

.section-intro {
  display: grid;
  gap: var(--padding-sm);
  justify-items: center;
  padding: 0 var(--padding-lg);
  margin: 0 auto var(--padding-lg);
  text-align: center;
}
.section-intro h2 {
  max-width: var(--mq-lg);
}
.section-intro p {
  max-width: var(--mq-xs);
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .stats {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
}

.stat {
  width: 80%;
  text-align: center;
}
@media (min-width: 640px) {
  .stat {
    width: 30rem;
  }
}

.stat__value {
  display: block;
  padding: 0 0 0.5rem;
  margin: 0 0 var(--padding-sm);
  border-bottom: 3px solid var(--color-lumo-green);
  font-weight: var(--font-weight-bold);
  font-size: var(--fs-fluid-max);
  color: var(--color-blue-dark);
  line-height: 1;
  letter-spacing: var(--minus-50);
}
.stat__value sup {
  margin: 0 0 0 0.5rem;
  font-size: 50%;
  font-weight: var(--font-weight-bold);
}

.stat__label {
  padding: 0 var(--padding-sm);
  line-height: var(--line-height-md);
}

.features-overview-outer {
  overflow: hidden;
}

.features-overview {
  max-width: var(--mq-xl);
  margin: 0 auto;
  padding: 0 var(--padding-md);
}
@media (min-width: 1800px) {
  .features-overview {
    padding: 0;
  }
}

.features-overview-intro {
  position: relative;
  background-color: var(--color-blue-dark);
}
.features-overview-intro:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  width: var(--padding-lg);
  height: 100%;
  background-color: var(--color-blue-dark);
}
@media (min-width: 1800px) {
  .features-overview-intro:before {
    width: calc((var(--mq-xxl) - var(--mq-xl)) / 2);
  }
}

.features-overview-intro__text {
  display: grid;
  gap: var(--padding-sm);
  max-width: var(--mq-xs);
  padding: var(--padding-lg) var(--padding-lg) var(--padding-lg) 0;
}
@media (min-width: 768px) {
  .features-overview-intro__text {
    padding: var(--padding-lg) 0;
  }
}

.features-overview-media {
  position: relative;
}
.features-overview-media:before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: var(--padding-lg);
  height: 100%;
  background-color: var(--color-purple-light);
}
@media (min-width: 1800px) {
  .features-overview-media:before {
    width: calc((var(--mq-xxl) - var(--mq-xl)) / 2);
  }
}
.features-overview-media img {
  display: block;
  width: 100%;
  height: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .features-overview-media img {
    flex-shrink: 0;
  }
}

.features-overview-grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--color-purple-light);
}
.features-overview-grid:before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: var(--padding-lg);
  height: 100%;
  background-color: var(--color-purple-light);
}
@media (min-width: 1800px) {
  .features-overview-grid:before {
    width: calc((var(--mq-xxl) - var(--mq-xl)) / 2);
  }
}

.features-overview-grid__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--padding-md);
  width: 100%;
  padding: var(--padding-lg);
}
@media (min-width: 768px) {
  .features-overview-grid__item {
    position: relative;
    width: 50%;
    padding: var(--padding-xl);
  }
}
.features-overview-grid__item .summary-text {
  display: none;
}
@media (min-width: 768px) {
  .features-overview-grid__item .icon-btn-list {
    position: absolute;
    bottom: var(--padding-xl);
    left: var(--padding-xl);
    z-index: 1;
  }
}
.features-overview-grid__item .tag {
  overflow: hidden;
  min-width: 0;
}
@media (min-width: 768px) {
  .features-overview-grid__item .tag {
    position: absolute;
    top: 3rem;
    left: var(--padding-xl);
    z-index: 1;
  }
}
@media (min-width: 1280px) {
  .features-overview-grid__item .tag {
    top: 4.5rem;
  }
}
.features-overview-grid__item .std-content ul {
  padding: 0;
  list-style-type: none;
}

@media (min-width: 768px) {
  .features-overview-grid__item--link {
    padding: var(--padding-xl) var(--padding-xl) calc(var(--padding-xl) * 2);
  }
}

.features-overview-grid__item:nth-child(4n+2) {
  background-color: rgba(120, 110, 176, 0.04);
}

.features-overview-grid__item:nth-child(4n+3) {
  background-color: rgba(120, 110, 176, 0.1);
}

.features-overview-grid__item:nth-child(4n+4) {
  background-color: rgba(120, 110, 176, 0.06);
}

.features-overview-grid__icon {
  height: 3rem;
  fill: var(--color-blue-dark);
}
@media (min-width: 1280px) {
  .features-overview-grid__icon {
    height: 5rem;
  }
}

.features-overview-grid__screenshot {
  display: block;
  width: 100%;
  height: auto;
}

.features-overview-grid--odd {
  position: relative;
}
@media (min-width: 768px) {
  .features-overview-grid--odd .features-overview-grid__item:last-child {
    width: 100%;
    padding-right: 50%;
  }
}
.features-overview-grid--odd .features-overview-grid__item:last-child .summary-text {
  display: block;
}
@media (min-width: 768px) {
  .features-overview-grid--odd .features-overview-grid__item:last-child .features-overview-grid__screenshot {
    position: absolute;
    top: var(--padding-xl);
    right: var(--padding-xl);
    z-index: 1;
    width: calc(50% - var(--padding-xl) * 2);
  }
}

.features-overview-grid--offices:before {
  display: none;
}
@media (min-width: 768px) {
  .features-overview-grid--offices .features-overview-grid__item {
    padding: var(--padding-xl);
  }
}

.client-grid-outer {
  position: relative;
  padding: 0 4rem;
}
.client-grid-outer .tns-outer {
  position: relative;
  z-index: 1;
}

.client-grid__logo {
  display: block;
  width: 50%;
  height: 8rem;
  margin: 0 auto;
  object-fit: contain;
  object-position: center;
}
@media (min-width: 1280px) {
  .client-grid__logo {
    width: 50%;
    height: 12rem;
  }
}

.client-grid-controls {
  position: absolute;
  top: calc(50% - 2rem);
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.client-grid-control {
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  background: none;
  border: none;
}
.client-grid-control svg {
  fill: var(--color-blue-dark);
}

.client-grid-control--prev svg {
  transform: rotate(180deg);
}

.client-grid-outer {
  position: relative;
}
.client-grid-outer .tns-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 4rem;
  margin: var(--padding-sm) 0 0;
}
@media (min-width: 1280px) {
  .client-grid-outer .tns-nav {
    display: none;
  }
}
.client-grid-outer .tns-nav button {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: var(--color-blue-dark);
}
.client-grid-outer .tns-nav button:hover {
  opacity: 0.5;
}
.client-grid-outer .tns-nav button.tns-nav-active {
  background-color: var(--color-lumo-green);
}

.client-grid-outer.is-disabled .client-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 1rem;
}
.client-grid-outer.is-disabled .client-grid > div {
  width: 25rem;
}
.client-grid-outer.is-disabled .client-grid-controls {
  display: none;
}

.media-summary {
  position: relative;
  display: grid;
}
@media (min-width: 1024px) {
  .media-summary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: start;
  }
}

.media-summary-text {
  order: 2;
  position: relative;
  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: start;
  padding: var(--padding-lg);
  background-color: var(--color-purple-light);
}
@media (min-width: 768px) {
  .media-summary-text {
    order: 1;
    width: 75%;
  }
  .media-summary-text:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 5rem;
    background-color: var(--color-purple-light);
  }
}
@media (min-width: 1024px) {
  .media-summary-text {
    width: auto;
    padding: var(--padding-xl) var(--padding-lg);
  }
  .media-summary-text:after {
    top: 0;
    left: 100%;
    z-index: 2;
    width: 10%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .media-summary-text .btn, .media-summary-text .tml-button, .media-summary-text .form-row input[type=submit], .form-row .media-summary-text input[type=submit],
  .media-summary-text .hs-submit input[type=submit],
  .hs-submit .media-summary-text input[type=submit] {
    position: absolute;
    bottom: 0;
    left: var(--padding-lg);
    z-index: 1;
  }
}

.media-summary-media {
  order: 1;
}
@media (min-width: 768px) {
  .media-summary-media {
    order: 2;
    position: relative;
    z-index: 3;
    width: 50%;
    margin: 0 0 0 50%;
  }
}
@media (min-width: 1024px) {
  .media-summary-media {
    width: auto;
    margin: 0;
  }
}

.media-summary-media__img {
  display: block;
  width: 100%;
  height: auto;
}

@media (min-width: 1024px) {
  .media-summary--featured .media-summary-text {
    padding: var(--padding-lg) var(--padding-xl) var(--padding-xl);
  }
}
@media (min-width: 1024px) {
  .media-summary--featured .media-summary-text .btn, .media-summary--featured .media-summary-text .tml-button, .media-summary--featured .media-summary-text .form-row input[type=submit], .form-row .media-summary--featured .media-summary-text input[type=submit],
  .media-summary--featured .media-summary-text .hs-submit input[type=submit],
  .hs-submit .media-summary--featured .media-summary-text input[type=submit] {
    left: var(--padding-xl);
  }
}

.media-summary--featured.is-hovering .title a, .media-summary--featured.is-hovering .hero-block-link a {
  text-decoration: underline;
}
.media-summary--featured.is-hovering .btn, .media-summary--featured.is-hovering .tml-button, .media-summary--featured.is-hovering .form-row input[type=submit], .form-row .media-summary--featured.is-hovering input[type=submit],
.media-summary--featured.is-hovering .hs-submit input[type=submit],
.hs-submit .media-summary--featured.is-hovering input[type=submit] {
  text-decoration: underline;
  color: var(--color-lumo-green);
}
.media-summary--featured.is-hovering .btn svg, .media-summary--featured.is-hovering .tml-button svg, .media-summary--featured.is-hovering .form-row input[type=submit] svg, .form-row .media-summary--featured.is-hovering input[type=submit] svg,
.media-summary--featured.is-hovering .hs-submit input[type=submit] svg,
.hs-submit .media-summary--featured.is-hovering input[type=submit] svg {
  transform: translateX(0.15rem);
}

.related-content-intro {
  position: relative;
  display: grid;
  gap: var(--padding-sm);
  width: 100%;
  padding: var(--padding-lg) var(--padding-lg) var(--padding-lg) 0;
  background-color: var(--color-purple-light);
}
@media (min-width: 768px) {
  .related-content-intro {
    width: 66%;
  }
}
.related-content-intro:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  width: var(--padding-lg);
  height: 100%;
  background-color: var(--color-purple-light);
}
@media (min-width: 1800px) {
  .related-content-intro:before {
    width: calc((var(--mq-xxl) - var(--mq-xl)) / 2);
  }
}

.related-content-feed {
  position: relative;
  display: grid;
  gap: var(--padding-md);
}
@media (min-width: 768px) {
  .related-content-feed {
    grid-template-columns: repeat(3, 1fr);
  }
}

.related-content .related-content-feed {
  background-color: var(--color-purple-light);
}
.related-content .related-content-feed:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  width: var(--padding-lg);
  height: 100%;
  background-color: var(--color-purple-light);
}
@media (min-width: 1800px) {
  .related-content .related-content-feed:before {
    width: calc((var(--mq-xxl) - var(--mq-xl)) / 2);
  }
}

.related-content-feed__item {
  display: flex;
  flex-direction: column;
}
@media (min-width: 640px) {
  .related-content-feed__item {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .related-content-feed__item {
    flex-direction: column;
  }
}

.related-content-feed__img {
  position: relative;
  display: block;
  aspect-ratio: 4/3;
}
@media (min-width: 640px) {
  .related-content-feed__img {
    width: 33.333%;
  }
}
@media (min-width: 768px) {
  .related-content-feed__img {
    width: 100%;
    background-color: var(--color-purple-light-fade);
  }
}
.related-content-feed__img img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.related-content-feed__text {
  position: relative;
  flex: 1;
  display: grid;
  gap: var(--padding-xs);
  align-content: start;
  padding: var(--padding-lg) var(--padding-md) var(--padding-xl);
  background: linear-gradient(0deg, rgba(120, 110, 176, 0) 0%, rgba(120, 110, 176, 0.1) 100%);
}
@media (min-width: 768px) {
  .related-content-feed__text {
    padding: var(--padding-md) var(--padding-md) var(--padding-xl);
  }
}
.related-content-feed__text .title a, .related-content-feed__text .hero-block-link a {
  color: var(--color-blue-dark);
  text-decoration: none;
}
.related-content-feed__text .title a:hover, .related-content-feed__text .hero-block-link a:hover {
  text-decoration: underline;
}
.related-content-feed__text .text-btn, .related-content-feed__text .tml-links a, .tml-links .related-content-feed__text a, .related-content-feed__text .std-content a, .std-content .related-content-feed__text a {
  position: absolute;
  bottom: var(--padding-md);
  left: var(--padding-md);
  z-index: 1;
}

.related-content-feed__text--no-link {
  padding: var(--padding-lg);
}
@media (min-width: 768px) {
  .related-content-feed__text--no-link {
    padding: var(--padding-md);
  }
}

.related-content-feed__links {
  margin: 0 0 0 2rem;
  line-height: var(--line-height-md);
  list-style-type: disc;
}

.related-content-feed__link {
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  color: var(--color-blue-dark);
}
.related-content-feed__link:hover {
  color: var(--color-purple);
}

.related-content-feed--team .related-content-feed__img {
  aspect-ratio: 1/1;
}

.related-content-feed--fixed .related-content-feed__img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 50%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
}
.related-content-feed--fixed .related-content-feed__img .tag {
  position: absolute;
  top: var(--padding-sm);
  left: var(--padding-md);
  z-index: 3;
}

.related-content-feed__item.is-hovering .title a, .related-content-feed__item.is-hovering .hero-block-link a {
  text-decoration: underline;
}

.hero {
  background-color: var(--color-blue-dark);
}
@media (min-width: 1024px) {
  .hero {
    display: grid;
    grid-template-columns: 40% 60%;
    min-height: 50rem;
  }
}

.hero-text {
  position: relative;
  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: center;
  padding: var(--padding-lg);
  background-color: var(--color-blue-dark);
}
@media (min-width: 1024px) {
  .hero-text {
    padding: var(--padding-lg) var(--padding-lg) var(--padding-xl);
  }
}
.hero-text .btn, .hero-text .tml-button, .hero-text .form-row input[type=submit], .form-row .hero-text input[type=submit],
.hero-text .hs-submit input[type=submit],
.hs-submit .hero-text input[type=submit] {
  margin: 1rem 0 0;
}
@media (min-width: 1024px) {
  .hero-text .btn, .hero-text .tml-button, .hero-text .form-row input[type=submit], .form-row .hero-text input[type=submit],
  .hero-text .hs-submit input[type=submit],
  .hs-submit .hero-text input[type=submit] {
    position: absolute;
    bottom: 0;
    left: var(--padding-lg);
    z-index: 1;
    margin: 0;
  }
}

@media (min-width: 1536px) {
  .hero .tag {
    position: absolute;
    top: var(--padding-md);
    left: var(--padding-lg);
    z-index: 1;
  }
}

@media (min-width: 1024px) {
  .hero-img {
    height: 100%;
  }
}

.hero-img__img {
  display: block;
  width: 100%;
  height: auto;
}
@media (min-width: 1024px) {
  .hero-img__img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.cta {
  position: relative;
  background-color: var(--color-blue-dark);
  overflow: hidden;
}
@media (min-width: 768px) {
  .cta {
    display: flex;
    height: 50rem;
  }
}

.cta-text {
  position: relative;
  z-index: 1;
  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: center;
  width: 100%;
  padding: var(--padding-lg);
}
.cta-text .btn, .cta-text .tml-button, .cta-text .form-row input[type=submit], .form-row .cta-text input[type=submit],
.cta-text .hs-submit input[type=submit],
.hs-submit .cta-text input[type=submit] {
  margin: var(--padding-sm) 0 0;
}

.cta-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.25;
  filter: grayscale(1);
}
@media (min-width: 768px) {
  .cta-img {
    position: relative;
    opacity: 1;
    filter: grayscale(0);
  }
}

@media (min-width: 768px) {
  .cta-text,
  .cta-img {
    width: 50%;
  }
}

.cta-img__img {
  display: block;
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .cta-img__img {
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 768px) {
  .cta--std .cta-img__img {
    object-position: left center;
  }
}

@media (min-width: 768px) {
  .cta--alt .cta-text {
    order: 2;
  }
}
@media (min-width: 768px) {
  .cta--alt .cta-img {
    order: 1;
  }
}
@media (min-width: 768px) {
  .cta--alt .cta-img__img {
    object-position: right center;
  }
}

.solutions {
  position: relative;
  background-color: var(--color-purple-light);
}
@media (min-width: 768px) {
  .solutions {
    padding: var(--padding-md) 0 0;
  }
}
@media (min-width: 768px) {
  .solutions:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: var(--padding-lg);
    height: var(--padding-md);
    background-color: white;
  }
}
@media (min-width: 1800px) {
  .solutions:before {
    width: calc((var(--mq-xxl) - var(--mq-xl)) / 2);
  }
}
.solutions > div {
  width: 100%;
}

@media (min-width: 768px) {
  .solution {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

.solution-text {
  position: relative;
  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: start;
  padding: var(--padding-lg);
  background-color: var(--color-purple-light);
}
@media (min-width: 1024px) {
  .solution-text {
    padding: var(--padding-xl);
  }
}
@media (min-width: 1024px) {
  .solution-text .btn, .solution-text .tml-button, .solution-text .form-row input[type=submit], .form-row .solution-text input[type=submit],
  .solution-text .hs-submit input[type=submit],
  .hs-submit .solution-text input[type=submit] {
    position: absolute;
    bottom: 0;
    left: var(--padding-xl);
    z-index: 1;
  }
}

.solution-img {
  aspect-ratio: 16/9;
  background-color: var(--color-blue-dark);
}
@media (min-width: 768px) {
  .solution-img {
    aspect-ratio: auto;
    position: relative;
  }
}
@media (min-width: 1280px) {
  .solution-img {
    min-height: 50rem;
  }
}

.solution-img__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 768px) {
  .solution-img__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.solutions .tns-nav {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 4rem;
}
@media (min-width: 768px) {
  .solutions .tns-nav {
    width: 50%;
    height: var(--padding-md);
    background-color: var(--color-blue-dark);
  }
}

.solutions .tns-nav button {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: var(--color-blue-dark);
}
@media (min-width: 768px) {
  .solutions .tns-nav button {
    background-color: white;
  }
}
.solutions .tns-nav button:hover {
  opacity: 0.5;
}
.solutions .tns-nav button.tns-nav-active {
  background-color: var(--color-lumo-green);
}

.services {
  position: relative;
}
@media (min-width: 768px) {
  .services:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: calc(100% - var(--padding-lg));
    height: var(--padding-md);
    background-color: var(--color-blue-dark);
  }
}
@media (min-width: 1024px) {
  .services:before {
    width: calc(100% - var(--padding-xl));
  }
}
@media (min-width: 768px) {
  .services {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: var(--padding-md) 0 0;
  }
}

.services-list {
  display: grid;
  gap: var(--padding-lg);
  padding: var(--padding-lg) var(--padding-lg) 0;
  background-color: var(--color-blue-dark);
}
@media (min-width: 768px) {
  .services-list {
    padding: var(--padding-lg) 0 0 var(--padding-lg);
  }
}
@media (min-width: 1024px) {
  .services-list {
    padding: var(--padding-xl) 0 0 var(--padding-xl);
  }
}

.services-list-intro {
  display: grid;
  gap: var(--padding-sm);
  place-items: start;
}
@media (min-width: 768px) {
  .services-list-intro {
    padding: 0 var(--padding-lg) 0 0;
  }
}

.service-info-toggles {
  display: none;
}
@media (min-width: 768px) {
  .service-info-toggles {
    display: block;
  }
}

@media (min-width: 768px) {
  .service-info-links {
    display: none;
  }
}

.service-info-links li:nth-child(odd) a {
  background-color: rgba(255, 255, 255, 0.1);
}
.service-info-links li:nth-child(even) a {
  background-color: rgba(255, 255, 255, 0.2);
}

.service-info-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--padding-sm);
  font-size: var(--fs-fluid-md);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  color: white;
  text-decoration: none;
}
.service-info-toggle svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
}

button.service-info-toggle {
  background-color: rgba(255, 255, 255, 0.1);
}
button.service-info-toggle:hover {
  background-color: rgba(255, 255, 255, 0.2);
  text-decoration: underline;
}
button.service-info-toggle:hover svg {
  transform: translateX(0.25rem);
}

button.service-info-toggle.is-active {
  background-color: var(--color-purple-light);
  color: var(--color-blue-dark);
}

.services-info {
  display: none;
}
@media (min-width: 768px) {
  .services-info {
    display: block;
    background-color: var(--color-purple-light);
  }
}

.service-info {
  display: none;
}
.service-info.is-visible {
  display: flex;
  flex-direction: column;
}

.service-info-img {
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
}
.service-info-img img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.service-info-text {
  position: relative;
  flex: 1;
  display: grid;
  gap: var(--padding-xs);
  align-content: start;
  padding: var(--padding-lg) var(--padding-lg) var(--padding-xl);
  background-color: var(--color-purple-light);
}
.service-info-text .btn, .service-info-text .tml-button, .service-info-text .form-row input[type=submit], .form-row .service-info-text input[type=submit],
.service-info-text .hs-submit input[type=submit],
.hs-submit .service-info-text input[type=submit] {
  position: absolute;
  bottom: 0;
  left: var(--padding-lg);
  z-index: 1;
}

.testimonials {
  position: relative;
}

.testimonial {
  display: grid;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .testimonial {
    align-items: center;
    grid-template-columns: 2fr 3fr;
  }
}
@media (min-width: 1280px) {
  .testimonial {
    padding: 0 var(--padding-xl);
  }
}

.testimonial-text {
  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: start;
}

@media (min-width: 640px) {
  .testimonial-img {
    display: grid;
    place-items: center;
    aspect-ratio: 1/1;
    border-top: 1px solid var(--color-lumo-green);
    border-right: 1px solid var(--color-lumo-green);
    border-left: 1px solid var(--color-lumo-green);
    border-bottom: 5px solid var(--color-lumo-green);
  }
}

.testimonial-img__img {
  display: block;
  width: auto;
  max-width: 12rem;
  max-height: 8rem;
  object-fit: contain;
}
@media (min-width: 1280px) {
  .testimonial-img__img {
    max-width: 18rem;
    max-height: 12rem;
  }
}

.testimonial-text__meta {
  color: var(--color-blue-dark);
}
.testimonial-text__meta strong {
  font-weight: var(--font-weight-bold);
}

.testimonials-controls {
  display: none;
}
@media (min-width: 1280px) {
  .testimonials-controls {
    position: absolute;
    top: calc(50% - (4rem + var(--padding-sm)));
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.testimonials-control {
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  background: none;
  border: none;
}
.testimonials-control svg {
  fill: var(--color-blue-dark);
}

.testimonials-control--prev svg {
  transform: rotate(180deg);
}

.testimonials {
  position: relative;
}
@media (min-width: 1024px) {
  .testimonials:before, .testimonials:after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 2;
    width: 5rem;
    height: 100%;
  }
}
.testimonials:before {
  left: 0;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}
.testimonials:after {
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}
.testimonials .tns-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 4rem;
  margin: var(--padding-sm) 0 0;
}
.testimonials .tns-nav button {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: var(--color-blue-dark);
}
.testimonials .tns-nav button:hover {
  opacity: 0.5;
}
.testimonials .tns-nav button.tns-nav-active {
  background-color: var(--color-lumo-green);
}

.mini-cta {
  padding: var(--padding-lg) var(--padding-lg) 0;
  background-color: var(--color-blue-dark);
}
@media (min-width: 1024px) {
  .mini-cta {
    padding: var(--padding-lg) var(--padding-xl) 0;
  }
}

.mini-cta-feed {
  position: relative;
  display: grid;
  gap: var(--padding-md);
}
@media (min-width: 768px) {
  .mini-cta-feed {
    grid-template-columns: repeat(3, 1fr);
  }
}

.mini-cta-feed__item {
  position: relative;
  display: grid;
  gap: var(--padding-xs);
  align-content: start;
  padding: var(--padding-md) var(--padding-md) var(--padding-xl);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
}
.mini-cta-feed__item .title a, .mini-cta-feed__item .hero-block-link a {
  color: white;
  text-decoration: none;
}
.mini-cta-feed__item .title a:hover, .mini-cta-feed__item .hero-block-link a:hover {
  text-decoration: underline;
}
.mini-cta-feed__item .text-btn, .mini-cta-feed__item .tml-links a, .tml-links .mini-cta-feed__item a, .mini-cta-feed__item .std-content a, .std-content .mini-cta-feed__item a {
  position: absolute;
  bottom: var(--padding-md);
  left: var(--padding-md);
  z-index: 1;
}

.text-img-outer--blue-light .text-img,
.text-img-outer--purple-light .text-img,
.text-img-outer--pink-light .text-img {
  padding: var(--padding-lg);
}
@media (min-width: 768px) {
  .text-img-outer--blue-light .text-img,
  .text-img-outer--purple-light .text-img,
  .text-img-outer--pink-light .text-img {
    padding: var(--padding-xl) var(--padding-lg);
  }
}

.text-img-outer--blue-light {
  background-color: var(--color-blue-light);
}

.text-img-outer--purple-light {
  background-color: var(--color-purple-light);
}

.text-img-outer--pink-light {
  background-color: var(--color-pink-light);
}

.text-img {
  display: grid;
  gap: var(--padding-lg);
  margin: 0 auto;
}
@media (min-width: 768px) {
  .text-img {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1024px) {
  .text-img {
    gap: var(--padding-xl);
  }
}

.text-img__img {
  display: block;
  width: 100%;
  height: auto;
}

.text-img__text {
  display: grid;
  place-items: start;
  gap: var(--padding-md);
}

@media (min-width: 768px) {
  .text-img-outer--alt .text-img__img,
  .text-img-outer--alt .testimonial-img {
    order: 2;
  }
}
@media (min-width: 768px) {
  .text-img-outer--alt .text-img__text {
    order: 1;
  }
}

.text-img.text-img--logo {
  padding: 0 var(--padding-lg);
}
@media (min-width: 640px) {
  .text-img.text-img--logo {
    grid-template-columns: 1fr 3fr;
  }
}
@media (min-width: 768px) {
  .text-img.text-img--logo {
    align-items: center;
  }
}
@media (min-width: 1280px) {
  .text-img.text-img--logo {
    padding: 0;
  }
}
.text-img.text-img--logo .testimonial-img {
  display: grid;
  place-items: center;
  aspect-ratio: 1/1;
  border-top: 1px solid var(--color-lumo-green);
  border-right: 1px solid var(--color-lumo-green);
  border-left: 1px solid var(--color-lumo-green);
  border-bottom: 5px solid var(--color-lumo-green);
}

@media (min-width: 640px) {
  .text-img-outer--alt .text-img--logo {
    grid-template-columns: 3fr 1fr;
  }
}

.form-row + .form-row,
* + .hs-form-field,
* + .legal-consent-container,
* + .hs-submit {
  margin: var(--padding-sm) 0 0;
}

.form-row + [data-class=wpcf7cf_group] {
  margin: var(--padding-sm) 0;
}

[data-class=wpcf7cf_group] + .form-row {
  margin: var(--padding-sm) 0;
}

.form-row__label,
.hs-form-field label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-md);
  color: var(--color-blue-dark);
  letter-spacing: var(--minus-20);
}

.form-row__field > p {
  line-height: var(--line-height-md);
}

.form-row input[type=text],
.hs-form-field input[type=text],
.form-row input[type=number],
.hs-form-field input[type=number],
.form-row input[type=url],
.hs-form-field input[type=url],
.form-row input[type=password],
.hs-form-field input[type=password],
.form-row input[type=email],
.hs-form-field input[type=email],
.form-row input[type=tel],
.hs-form-field input[type=tel],
.form-row input[type=date],
.hs-form-field input[type=date],
.form-row select,
.hs-form-field select {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;
  border: 2px solid var(--color-blue-mid);
  background-color: white;
  font-family: "Manrope", sans-serif;
  font-size: var(--fs-fluid-sm);
}
.form-row input[type=text]:focus,
.hs-form-field input[type=text]:focus,
.form-row input[type=number]:focus,
.hs-form-field input[type=number]:focus,
.form-row input[type=url]:focus,
.hs-form-field input[type=url]:focus,
.form-row input[type=password]:focus,
.hs-form-field input[type=password]:focus,
.form-row input[type=email]:focus,
.hs-form-field input[type=email]:focus,
.form-row input[type=tel]:focus,
.hs-form-field input[type=tel]:focus,
.form-row input[type=date]:focus,
.hs-form-field input[type=date]:focus,
.form-row select:focus,
.hs-form-field select:focus {
  outline: none;
  border-color: var(--color-blue-dark);
}
.form-row input[type=text].wpcf7-not-valid,
.hs-form-field input[type=text].wpcf7-not-valid,
.form-row input[type=number].wpcf7-not-valid,
.hs-form-field input[type=number].wpcf7-not-valid,
.form-row input[type=url].wpcf7-not-valid,
.hs-form-field input[type=url].wpcf7-not-valid,
.form-row input[type=password].wpcf7-not-valid,
.hs-form-field input[type=password].wpcf7-not-valid,
.form-row input[type=email].wpcf7-not-valid,
.hs-form-field input[type=email].wpcf7-not-valid,
.form-row input[type=tel].wpcf7-not-valid,
.hs-form-field input[type=tel].wpcf7-not-valid,
.form-row input[type=date].wpcf7-not-valid,
.hs-form-field input[type=date].wpcf7-not-valid,
.form-row select.wpcf7-not-valid,
.hs-form-field select.wpcf7-not-valid {
  border-color: var(--color-error);
}

.form-row select,
.hs-form-field select {
  padding: 0 3rem 0 1rem;
}

.form-row input[type=date],
.hs-form-field input[type=date] {
  text-transform: uppercase;
}

.form-row textarea,
.hs-form-field textarea {
  display: block;
  width: 100%;
  height: 30rem;
  padding: 1rem;
  border: 2px solid var(--color-blue-mid);
  background-color: white;
  font-size: var(--fs-fluid-sm);
}
.form-row textarea:focus,
.hs-form-field textarea:focus {
  outline: none;
  border-color: var(--color-blue-dark);
}
.form-row textarea.wpcf7-not-valid,
.hs-form-field textarea.wpcf7-not-valid {
  border-color: var(--color-error);
}

.wpcf7-checkbox,
.wpcf7-radio {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-xs);
}

.wpcf7-checkbox .wpcf7-list-item label,
.wpcf7-radio .wpcf7-list-item label {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.form-row .wpcf7-checkbox .wpcf7-list-item-label,
.form-row .wpcf7-radio .wpcf7-list-item-label {
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-dark);
  line-height: var(--line-height-md);
}

.wpcf7-acceptance,
.form-row__gdpr {
  display: block;
  width: 100%;
  padding: var(--padding-sm);
  background-color: var(--color-blue-light);
}
.wpcf7-acceptance a,
.form-row__gdpr a {
  color: inherit;
}

.wpcf7-acceptance input,
.form-row__gdpr input {
  margin: 0 1rem 0 0;
}

.wpcf7-acceptance .wpcf7-list-item-label,
.form-row__gdpr label {
  font-size: var(--fs-fluid-xs);
  line-height: var(--line-height-md);
}

.screen-reader-response {
  display: none;
}

.form-field-alert {
  padding: 1rem;
  background-color: var(--color-blue-light);
}
.form-field-alert p {
  font-weight: var(--font-weight-bold);
}

.wpcf7-form.invalid .form-field-alert {
  background-color: var(--color-error);
}
.wpcf7-form.invalid .form-field-alert p {
  color: white;
}

.wpcf7-not-valid-tip {
  display: inline-block;
  padding: 1rem;
  background-color: var(--color-error);
  font-size: var(--fs-fluid-xs);
  font-weight: var(--font-weight-bold);
  color: white;
}

.wpcf7-response-output {
  display: block;
  padding: 1rem;
  font-weight: var(--font-weight-bold);
  font-size: var(--fs-fluid-sm);
  color: white;
}

.wpcf7-form.invalid .wpcf7-response-output {
  margin: var(--padding-md) 0 0;
  background-color: var(--color-error);
}

.wpcf7-form.sent .wpcf7-response-output {
  margin: var(--padding-md) 0 0;
  background-color: var(--color-success);
}

.wpcf7-checkbox + .wpcf7-not-valid-tip,
.wpcf7-radio + .wpcf7-not-valid-tip {
  margin: 1rem 0 0;
}

.form-error {
  display: none;
  padding: 1rem;
  background-color: var(--color-error);
  font-size: var(--fs-fluid-xs);
  font-weight: var(--font-weight-bold);
  color: white;
}

.legal-consent-container {
  display: grid;
  gap: var(--padding-xs);
  padding: var(--padding-sm);
  background-color: var(--color-blue-light);
}

.hbspt-form .submitted-message {
  padding: var(--padding-md);
  background-color: var(--color-success);
  font-size: var(--fs-fluid-md);
  font-weight: var(--font-weight-bold);
  color: white;
}

.hs-error-msgs {
  display: block;
  padding: 1rem;
  margin: var(--padding-xs) 0 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--fs-fluid-sm);
  color: white;
  background-color: var(--color-error);
}
.hs-error-msgs li + li {
  margin: 1rem 0 0;
}

.hs-form-field .hs-error-msgs label {
  margin: 0;
  color: white;
}

.hs-form-radio-display,
.hs-form-checkbox-display,
.hs-form-booleancheckbox-display {
  cursor: pointer;
}

.hs-form-booleancheckbox p a {
  color: inherit;
}

.legal-consent-container .hs-form-booleancheckbox-display > span {
  margin-left: 25px !important;
}

.tag {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  font-weight: var(--font-weight-bold);
}

.tag--std {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.tag--featured {
  background-color: white;
  color: var(--color-blue-dark);
}

.tag--services {
  background-color: white;
  color: var(--color-services);
}

.tag--solutions {
  background-color: white;
  color: var(--color-solutions);
}

.tag--platforms {
  background-color: white;
  color: var(--color-platform);
}

.tag--ligentix {
  background-color: var(--color-lumo-green);
  color: var(--color-blue-dark);
}

.hero-blocks {
  display: grid;
  gap: var(--padding-sm);
}
@media (min-width: 768px) {
  .hero-blocks {
    grid-template-columns: 1.5fr 1fr;
  }
}

.hero-block-img {
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 2/1;
}
.hero-block-img img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hero-block-text {
  line-height: var(--line-height-lg);
}
@media (min-width: 1280px) {
  .hero-block-text {
    font-size: var(--fs-fluid-md);
  }
}

.hero-block-link {
  display: flex;
  flex-direction: column;
  gap: var(--padding-md);
  padding: 0 var(--padding-lg) var(--padding-md);
  text-decoration: none;
  color: var(--color-blue-dark);
  letter-spacing: var(--minus-20);
  line-height: var(--line-height-sm);
}
@media (min-width: 768px) {
  .hero-block-link {
    padding: 0 var(--padding-md) var(--padding-lg);
  }
}
@media (min-width: 1280px) {
  .hero-block-link {
    flex-direction: row;
    gap: var(--padding-lg);
    align-items: center;
    padding: 0 var(--padding-md) var(--padding-lg);
  }
}
.hero-block-link svg {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
  flex-shrink: 0;
}
.hero-block-link:hover {
  text-decoration: underline;
}
.hero-block-link:hover svg {
  transform: translateX(0.25rem);
}

.hero-col-1 .hero-block {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .hero-col-1 .hero-block {
    height: 100%;
  }
}
.hero-col-1 .hero-block .hero-text {
  order: 2;
}
@media (min-width: 768px) {
  .hero-col-1 .hero-block .hero-text {
    order: 1;
    flex: 1;
  }
}
@media (min-width: 1536px) {
  .hero-col-1 .hero-block .hero-text {
    align-content: start;
    padding: var(--padding-lg) 30% var(--padding-xl) var(--padding-lg);
  }
}
.hero-col-1 .hero-block .supporting-text {
  display: none;
}
@media (min-width: 768px) {
  .hero-col-1 .hero-block .supporting-text {
    display: block;
  }
}
.hero-col-1 .hero-block .hero-block-img {
  order: 1;
}
@media (min-width: 768px) {
  .hero-col-1 .hero-block .hero-block-img {
    order: 2;
  }
}

.hero-col-2 {
  display: grid;
  gap: var(--padding-sm);
}
@media (min-width: 768px) {
  .hero-col-2 {
    grid-template-rows: 1fr 1fr;
  }
}
.hero-col-2 .hero-block {
  display: grid;
  justify-items: start;
  align-content: center;
  background-color: var(--color-purple-light);
}
@media (min-width: 1280px) {
  .hero-col-2 .hero-block {
    align-content: start;
  }
}
.hero-col-2 .hero-block-img {
  display: none;
}
@media (min-width: 1280px) {
  .hero-col-2 .hero-block-img {
    display: block;
  }
}
.hero-col-2 .tag {
  margin: var(--padding-lg) var(--padding-lg) var(--padding-sm);
}
@media (min-width: 768px) {
  .hero-col-2 .tag {
    margin: var(--padding-md) var(--padding-md) var(--padding-sm);
  }
}

.feed-hero {
  position: relative;
  z-index: 1;
  max-height: 50rem;
  padding: var(--padding-lg);
  background-color: var(--color-blue-dark);
}
.feed-hero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(img/topography-gradient-1.png) no-repeat right -250px center;
  background-size: auto 500px;
  opacity: 0.15;
  filter: grayscale(1);
}
@media (min-width: 768px) {
  .feed-hero:before {
    opacity: 1;
    filter: grayscale(0);
  }
}
@media (min-width: 1280px) {
  .feed-hero:before {
    background-size: auto 750px;
  }
}

.feed-hero--1:before {
  background: url(img/topography-gradient-1.png) no-repeat right -250px center;
  background-size: auto 500px;
}

.feed-hero--2:before {
  background: url(img/topography-gradient-2.png) no-repeat right -250px center;
  background-size: auto 600px;
}

.feed-hero--3:before {
  background: url(img/topography-gradient-3.png) no-repeat right -250px center;
  background-size: auto 550px;
}

.feed-hero--4:before {
  background: url(img/topography-gradient-4.png) no-repeat right -250px center;
  background-size: auto 650px;
}

@media (min-width: 768px) {
  .feed-hero--vacancy:before {
    opacity: 0.25;
    filter: grayscale(1);
  }
}

.feed-hero-text {
  position: relative;
  z-index: 2;
  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: center;
}
@media (min-width: 768px) {
  .feed-hero-text {
    width: 50%;
  }
}

.post-meta {
  display: flex;
  align-items: center;
  gap: var(--padding-lg);
  flex-wrap: wrap;
  margin: var(--padding-md) 0 0;
}

.post-meta-author {
  display: flex;
  align-items: center;
  gap: var(--padding-xs);
}
.post-meta-author:before {
  content: "";
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background: linear-gradient(45deg, rgb(50, 170, 225) 0%, rgb(200, 210, 60) 100%);
}
@media (min-width: 1280px) {
  .post-meta-author:before {
    width: 5rem;
    height: 5rem;
  }
}

.vacancy-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
  padding: 0;
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  line-height: var(--line-height-md);
  color: white;
  list-style-type: disc;
}
.vacancy-meta li {
  margin: 0 0 0 1.5rem;
  padding: 0 0 0 0.5rem;
}
.vacancy-meta li:first-child {
  margin: 0;
  padding: 0;
}
.vacancy-meta li:first-child::marker {
  content: "";
}

.feed-hero--vacancy .feed-hero-text {
  max-width: calc(var(--mq-sm) - var(--padding-md) * 2);
  margin: 0 auto;
}

.pagination > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--padding-xs);
}

.pagination-pg {
  display: block;
  width: 2rem;
  height: 2rem;
  background: none;
  font-weight: var(--font-weight-bold);
  text-decoration: none;
  text-align: center;
  color: var(--color-blue-dark);
}
.pagination-pg:hover {
  color: var(--color-purple);
}
.pagination-pg.mixitup-control-active {
  text-decoration: underline;
}

.pagination-control {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
  background: none;
}
.pagination-control svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--color-blue-dark);
}

.pagination-control--prev svg {
  transform: rotate(180deg);
}

.vacancies-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--padding-md);
}

.vacancies-filter {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.vacancies-filter select {
  display: block;
  height: 5rem;
  padding: 0 3rem 0 1rem;
  border: 2px solid var(--color-blue-mid);
  background-color: white;
  font-family: "Manrope", sans-serif;
  font-size: var(--fs-fluid-sm);
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 2rem;
}
.vacancies-filter select:focus {
  outline: none;
  border-color: var(--color-blue-dark);
}

.vacancies-outer {
  padding: 0 0 4rem;
}
@media (max-width: 768px) {
  .vacancies-outer {
    overflow-x: scroll;
  }
}

.vacancies-list {
  width: 100%;
  border-collapse: collapse;
}
@media (max-width: 768px) {
  .vacancies-list {
    width: var(--mq-lg);
  }
}
.vacancies-list th, .vacancies-list td {
  border: 0;
}
.vacancies-list th {
  width: 30%;
  padding: 1rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-dark);
}
.vacancies-list td {
  vertical-align: top;
  padding: 2rem 1rem 0;
  line-height: var(--line-height-md);
}
@media (min-width: 768px) {
  .vacancies-list td {
    padding: 4rem 1rem 0;
  }
}

.vacancies-list__header {
  background-color: var(--color-purple-light);
}

.vacancies-list__link {
  width: 10%;
}

@media (min-width: 768px) {
  .vacancies-helper {
    display: none;
  }
}

.staff-testimonials-outer {
  position: relative;
  z-index: 1;
  padding: var(--padding-xl) 0;
  background-color: var(--color-blue-dark);
}
.staff-testimonials-outer > .inner {
  position: relative;
  z-index: 2;
}
.staff-testimonials-outer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(img/topography-gradient-1.png) no-repeat right -600px center;
  background-size: auto 500px;
  opacity: 0.25;
  filter: grayscale(1);
}
@media (min-width: 1536px) {
  .staff-testimonials-outer:before {
    opacity: 1;
    filter: grayscale(0);
  }
}
@media (min-width: 1280px) {
  .staff-testimonials-outer:before {
    background-size: auto 750px;
  }
}

.staff-testimonials {
  display: grid;
  gap: var(--padding-lg);
  max-width: var(--mq-sm);
  margin: 0 auto;
  padding: 0 var(--padding-md);
}

.staff-testimonial {
  display: grid;
  gap: var(--padding-sm);
}

.staff-testimonial + .staff-testimonial {
  padding: var(--padding-lg) 0 0;
  border-top: 1px solid var(--color-blue-mid);
}

.glossary-outer {
  display: grid;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .glossary-outer {
    display: grid;
    gap: var(--padding-xl);
    grid-template-columns: 2fr 1fr;
  }
}

.glossary {
  order: 2;
}
@media (min-width: 768px) {
  .glossary {
    order: 1;
  }
}

.glossary-nav-outer {
  order: 1;
}
@media (min-width: 768px) {
  .glossary-nav-outer {
    order: 2;
    position: relative;
  }
}
@media (min-width: 768px) {
  .glossary-nav-outer .filters {
    position: sticky;
    top: 0;
  }
}
.glossary-nav-outer .filter {
  width: 4rem;
  padding: 0.5rem 1rem;
  text-align: center;
}

.glossary-section-marker {
  display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;
  font-size: var(--fs-fluid-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-dark);
  background-color: var(--color-purple-light);
}

.glossary-item {
  padding: var(--padding-lg);
  margin: 0 0 var(--padding-xs);
  background-color: var(--color-purple-light);
}

.filters {
  display: grid;
  gap: 0.5rem;
}

.filter-list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.filter {
  display: block;
  padding: 0.5rem;
  font-size: 1.4rem;
  font-weight: var(--font-weight-bold);
  color: white;
  text-decoration: none;
}

.filter--white {
  background-color: white;
  color: var(--color-blue-dark);
}
.filter--white:hover {
  background-color: var(--color-lumo-green);
}

.filter--blue-dark {
  background-color: var(--color-blue-dark);
  color: white;
}
.filter--blue-dark:hover {
  opacity: var(--hover-opacity);
}

.img-banner {
  display: block;
  width: 100%;
  height: auto;
}

.gated-cta {
  display: grid;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .gated-cta {
    grid-template-columns: 2fr 1fr;
  }
}
@media (min-width: 1024px) {
  .gated-cta {
    gap: var(--padding-xl);
  }
}

.gated-cta-content {
  order: 2;
  display: grid;
  gap: var(--padding-lg);
}
@media (min-width: 768px) {
  .gated-cta-content {
    order: 1;
  }
}

.gated-cta-img {
  order: 1;
}
@media (min-width: 768px) {
  .gated-cta-img {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    order: 2;
  }
}

.gated-cta-img__img {
  display: block;
  width: 20rem;
  height: auto;
}
@media (min-width: 768px) {
  .gated-cta-img__img {
    width: 75%;
  }
}

.package-type-other,
.commodity-type-other {
  margin: 1rem 0 0;
}

.quote-form-actions,
.quiz-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-md);
  padding: var(--padding-md) 0 0;
}

.quote-form-markers,
.quiz-form-markers {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quote-form-markers:before,
.quiz-form-markers:before {
  content: "";
  position: absolute;
  top: 2rem;
  left: calc(2rem - 2px);
  transform: translateY(-50%);
  z-index: 1;
  width: calc(100% - 4rem);
  height: 4px;
  background-color: var(--color-blue-light);
}

.quote-form-marker,
.quiz-marker {
  position: relative;
  z-index: 1;
  display: block;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-mid);
  background-color: var(--color-blue-light);
  border-radius: 100%;
}

.quote-form--step-1 .quote-form-marker--1,
.quote-form--step-2 .quote-form-marker--2,
.quote-form--step-3 .quote-form-marker--3,
.quiz-marker.current-marker {
  color: white;
  background-color: var(--color-blue-dark);
}

.quiz-options {
  display: grid;
  gap: var(--padding-sm);
}

.quiz-option {
  display: flex;
  width: 100%;
  gap: 1rem;
  padding: var(--padding-sm);
  border: 2px solid var(--color-blue-mid);
  background-color: white;
  font-family: "Manrope", sans-serif;
  font-size: var(--fs-fluid-sm);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}
.quiz-option:hover {
  border-color: var(--color-blue-dark);
}

.bio-intro {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  background-color: var(--color-purple-light);
}
@media (min-width: 768px) {
  .bio-intro {
    grid-template-columns: 1fr 1fr;
  }
}

.bio-intro__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-md);
}

.responsive-table {
  position: relative;
}

.responsive-table table {
  border-collapse: collapse;
  border-spacing: 0px;
  background-color: var(--color-purple-light);
  display: inline-block;
  vertical-align: top;
}
.responsive-table table tr:not(:last-child) td {
  border-bottom: 1px solid white;
}
.responsive-table table th {
  font-weight: var(--font-weight-bold);
  border-bottom: 3px solid var(--color-purple-light-fade);
}
.responsive-table table th, .responsive-table table td {
  padding: 1.5rem;
  font-size: var(--fs-fluid-xs);
  vertical-align: top;
  text-align: left;
}

.responsive-table__helper {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0 0 1rem;
}

.ligentix-carousel-outer {
  position: relative;
}
@media (min-width: 768px) {
  .ligentix-carousel-outer {
    padding: 0 var(--padding-md);
  }
}
.ligentix-carousel-outer .tns-outer {
  margin: 0 4rem;
}
@media (min-width: 768px) {
  .ligentix-carousel-outer .tns-outer {
    margin: 0;
  }
}

.ligentix-carousel {
  background-color: var(--color-purple-light);
}

.ligentix-carousel-controls {
  position: absolute;
  top: calc(50% - 2rem);
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 768px) {
  .ligentix-carousel-controls {
    display: none;
  }
}

.ligentix-carousel-control {
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  background: none;
  border: none;
}
.ligentix-carousel-control svg {
  fill: var(--color-blue-dark);
}

.ligentix-carousel-control--prev svg {
  transform: rotate(180deg);
}

.ligentix-carousel-nav-outer {
  display: none;
  padding: var(--padding-md);
  background-color: var(--color-purple-light);
}
@media (min-width: 768px) {
  .ligentix-carousel-nav-outer {
    display: block;
  }
}
@media (min-width: 1024px) {
  .ligentix-carousel-nav-outer {
    padding: var(--padding-lg) var(--padding-lg) 0;
  }
}

.ligentix-carousel-nav {
  position: relative;
  display: flex;
  justify-content: space-evenly;
}
.ligentix-carousel-nav:after {
  content: "";
  position: absolute;
  top: calc(6rem + 3px);
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--color-blue-dark);
}

.ligentix-carousel-nav--3 .ligentix-carousel-nav__toggle {
  width: 33.333%;
}

.ligentix-carousel-nav--4 .ligentix-carousel-nav__toggle {
  width: 25%;
}

.ligentix-carousel-nav--5 .ligentix-carousel-nav__toggle {
  width: 20%;
}

.ligentix-carousel-nav__toggle {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  text-align: center;
  background: none;
}
.ligentix-carousel-nav__toggle:hover {
  text-decoration: underline;
}
.ligentix-carousel-nav__toggle.tns-nav-active .ligentix-carousel-nav__marker {
  background-color: var(--color-lumo-green);
}

.ligentix-carousel-nav__icon {
  display: block;
  width: 5rem;
  height: 5rem;
}

.ligentix-carousel-nav__marker {
  display: block;
  width: 8rem;
  height: 6px;
  background-color: var(--color-blue-dark);
}

.ligentix-carousel__slide__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: var(--padding-sm) var(--padding-md) 0;
}
@media (min-width: 768px) {
  .ligentix-carousel__slide__header {
    display: none;
  }
}

.ligentix-carousel__slide__content {
  display: grid;
  gap: var(--padding-md);
  padding: var(--padding-sm) var(--padding-md) var(--padding-lg);
}
@media (min-width: 768px) {
  .ligentix-carousel__slide__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .ligentix-carousel__slide__content {
    grid-template-columns: 1.5fr 1fr;
    gap: var(--padding-lg);
    padding: var(--padding-lg);
  }
}

.ligentix-carousel__slide__img {
  display: block;
  width: 100%;
  height: auto;
}

.ligentix-carousel__slide__text h2 {
  display: none;
}
@media (min-width: 768px) {
  .ligentix-carousel__slide__text h2 {
    display: block;
  }
}

.doc-grids {
  display: grid;
  gap: var(--padding-lg);
}

.doc-grid {
  display: grid;
  gap: var(--padding-sm);
}
@media (min-width: 640px) {
  .doc-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.doc-grid__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-sm);
  height: 100%;
  padding: var(--padding-md);
  background-color: var(--color-blue-dark);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  text-align: center;
  color: white;
  text-decoration: none;
}
.doc-grid__link:hover {
  text-decoration: underline;
}
.doc-grid__link:hover svg {
  fill: white;
}
.doc-grid__link svg {
  width: 5rem;
  height: 5rem;
  fill: var(--color-lumo-green);
}

.login-form {
  padding: var(--padding-lg);
  background-color: var(--color-purple-light);
}

.tml-field-wrap + .tml-field-wrap {
  margin: var(--padding-md) 0 0;
}

.tml-label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-dark);
  line-height: var(--line-height-md);
}

.tml-field-wrap input[type=text],
.tml-field-wrap input[type=number],
.tml-field-wrap input[type=url],
.tml-field-wrap input[type=password],
.tml-field-wrap input[type=email],
.tml-field-wrap input[type=tel],
.tml-field-wrap input[type=date],
.tml-field-wrap select {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border: 1px solid var(--color-blue-mid);
  background-color: white;
  font-size: var(--fs-fluid-sm);
}
.tml-field-wrap input[type=text]:focus,
.tml-field-wrap input[type=number]:focus,
.tml-field-wrap input[type=url]:focus,
.tml-field-wrap input[type=password]:focus,
.tml-field-wrap input[type=email]:focus,
.tml-field-wrap input[type=tel]:focus,
.tml-field-wrap input[type=date]:focus,
.tml-field-wrap select:focus {
  outline: none;
  border-color: var(--color-blue-dark);
}

.tml-rememberme-wrap {
  margin: var(--padding-sm) 0 0;
}
.tml-rememberme-wrap label {
  display: inline;
  margin: 0;
}

.tml-errors {
  padding: var(--padding-xs);
  margin: 0 0 var(--padding-xs);
  font-size: var(--font-weight-bold);
  font-weight: var(--font-weight-bold);
  color: white;
  list-style-type: none;
  background-color: var(--color-error);
}

.tml-error {
  color: white;
}
.tml-error strong {
  font-weight: inherit;
  text-decoration: underline;
}

.tml-error + .tml-error {
  margin: 1rem 0 0;
}

.tml-links {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-xs);
  margin: var(--padding-md) 0 0;
  list-style-type: none;
}
.no-flexbox-gap .tml-links li + .tml-links li {
  margin: var(--padding-xs) 0 0;
}

.tml-messages {
  margin: 0 0 var(--padding-md);
  padding: var(--padding-xs);
  list-style-type: none;
  background-color: var(--color-success);
}

.tml-message {
  font-weight: var(--font-weight-bold);
  color: white;
}
.tml-message a {
  color: inherit;
}

.tml-message + .tml-message {
  margin: 1rem 0 0;
}

.tml-register-link {
  display: none;
}

.tml-2fa {
  margin: var(--padding-md) 0 0;
}

.tml-description {
  display: block;
  margin: 1rem 0 0;
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.3333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: 2333.3333333333%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.4285714%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: 1.4285714286%;
  height: 10px;
  float: left;
}

/*# sourceMappingURL=sourcemaps/tiny-slider.css.map */