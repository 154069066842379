.text-img-outer--blue-light,
.text-img-outer--purple-light,
.text-img-outer--pink-light {
  
  .text-img {
    padding: var(--padding-lg);

      @include mq(screen-sm) {
        padding: var(--padding-xl) var(--padding-lg);
      }

  }

}


.text-img-outer--blue-light {
  background-color: var(--color-blue-light);
}

.text-img-outer--purple-light {
  background-color: var(--color-purple-light);
}

.text-img-outer--pink-light {
  background-color: var(--color-pink-light);
}

.text-img {
  display: grid;
  gap: var(--padding-lg);
  margin: 0 auto;

  @extend .inner--lg;

    @include mq(screen-sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include mq(screen-md) {
      gap: var(--padding-xl);
    }

}

.text-img__img {
  display: block;
  width: 100%;
  height: auto;
}

.text-img__text {
  display: grid;
  place-items: start;
  gap: var(--padding-md);
}

// Orientation

.text-img-outer--alt {
  
  .text-img__img,
  .testimonial-img {
    
    @include mq(screen-sm) {
      order: 2;
    }

  }

  .text-img__text {
    
    @include mq(screen-sm) {
      order: 1;
    }

  }

}

// Variants

.text-img.text-img--logo {
  padding: 0 var(--padding-lg);

  @include mq(screen-xs) {
    grid-template-columns: 1fr 3fr;
  }

  @include mq(screen-sm) {
    align-items: center;
  }

  @include mq(screen-lg) {
    padding: 0;
  }

  .testimonial-img {
    display: grid;
    place-items: center;

    aspect-ratio: 1/1;

    border-top: 1px solid var(--color-lumo-green);
    border-right: 1px solid var(--color-lumo-green);
    border-left: 1px solid var(--color-lumo-green);
    border-bottom: 5px solid var(--color-lumo-green);
  }

}

.text-img-outer--alt .text-img--logo {
  
  
  @include mq(screen-xs) {
    grid-template-columns: 3fr 1fr;
  }

}