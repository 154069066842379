.form-row + .form-row,
* + .hs-form-field,
* + .legal-consent-container,
* + .hs-submit {
  margin: var(--padding-sm) 0 0;
}

.form-row + [data-class="wpcf7cf_group"] {
  margin: var(--padding-sm) 0;
}

[data-class="wpcf7cf_group"] + .form-row {
  margin: var(--padding-sm) 0;
}

.form-row__label,
.hs-form-field label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;

  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-md);
  color: var(--color-blue-dark);
  letter-spacing: var(--minus-20);
}

.form-row__field > p {
  line-height: var(--line-height-md);
}

// Single line field styles

.form-row input[type="text"],
.hs-form-field input[type="text"],
.form-row input[type="number"],
.hs-form-field input[type="number"],
.form-row input[type="url"],
.hs-form-field input[type="url"],
.form-row input[type="password"],
.hs-form-field input[type="password"],
.form-row input[type="email"],
.hs-form-field input[type="email"],
.form-row input[type="tel"],
.hs-form-field input[type="tel"],
.form-row input[type="date"],
.hs-form-field input[type="date"],
.form-row select,
.hs-form-field select {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;

  border: 2px solid var(--color-blue-mid);
  background-color: white;

  font-family: 'Manrope', sans-serif;
  font-size: var(--fs-fluid-sm);

    &:focus {
      outline: none;
      border-color: var(--color-blue-dark);
    }

    &.wpcf7-not-valid {
      border-color: var(--color-error);
    }


}

.form-row select,
.hs-form-field select {
  padding: 0 3rem 0 1rem;
}

.form-row input[type="date"],
.hs-form-field input[type="date"] {
  text-transform: uppercase;
}

.form-row input[type="submit"],
.hs-submit input[type="submit"] {
  @extend .btn;
  @extend .btn--blue-dark;
}



// Textarea field styles

.form-row textarea,
.hs-form-field textarea {
  display: block;
  width: 100%;
  height: 30rem;
  padding: 1rem;
  
  border: 2px solid var(--color-blue-mid);
  background-color: white;

  font-size: var(--fs-fluid-sm);

    &:focus {
      outline: none;
      border-color: var(--color-blue-dark);
    }

    &.wpcf7-not-valid {
      border-color: var(--color-error);
    }

}

// Checkboxes and radios

.wpcf7-checkbox,
.wpcf7-radio {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-xs);
}

.wpcf7-checkbox .wpcf7-list-item label,
.wpcf7-radio .wpcf7-list-item label {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.form-row .wpcf7-checkbox .wpcf7-list-item-label,
.form-row .wpcf7-radio .wpcf7-list-item-label {
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-dark);
  line-height: var(--line-height-md);
}

// GDPR

.wpcf7-acceptance,
.form-row__gdpr {
  display: block;
  width: 100%;
  padding: var(--padding-sm);

  background-color: var(--color-blue-light);

    a {
      color: inherit;
    }
}

.wpcf7-acceptance input,
.form-row__gdpr input {
  margin: 0 1rem 0 0;
}

.wpcf7-acceptance .wpcf7-list-item-label,
.form-row__gdpr label {
  font-size: var(--fs-fluid-xs);
  // color: var(--color-error);
  line-height: var(--line-height-md);
}

// Validation

.screen-reader-response {
  display: none;
}

.form-field-alert {
  padding: 1rem;

  background-color: var(--color-blue-light);

    p {
      font-weight: var(--font-weight-bold);
    }

}

.wpcf7-form.invalid .form-field-alert {
  background-color: var(--color-error);

    p {
      color: white;
    }

}

.wpcf7-not-valid-tip {
  display: inline-block;
  padding: 1rem;

  background-color: var(--color-error);

  font-size: var(--fs-fluid-xs);
  font-weight: var(--font-weight-bold);
  color: white;
}

.wpcf7-response-output {
  display: block;
  padding: 1rem;

  font-weight: var(--font-weight-bold);
  font-size: var(--fs-fluid-sm);
  color: white;
}

.wpcf7-form.invalid .wpcf7-response-output {
  margin: var(--padding-md) 0 0;

  background-color: var(--color-error);
}

.wpcf7-form.sent .wpcf7-response-output {
  margin: var(--padding-md) 0 0;
  
  background-color: var(--color-success);
}

.wpcf7-checkbox + .wpcf7-not-valid-tip,
.wpcf7-radio + .wpcf7-not-valid-tip {
  margin: 1rem 0 0;
}

// Quote form validation

.form-error {
  display: none;
  padding: 1rem;

  background-color: var(--color-error);

  font-size: var(--fs-fluid-xs);
  font-weight: var(--font-weight-bold);
  color: white;
}

// Hubspot specific styles

.legal-consent-container {
  display: grid;
  gap: var(--padding-xs);
  padding: var(--padding-sm);

  background-color: var(--color-blue-light);
}

.hbspt-form .submitted-message {
  padding: var(--padding-md);
  background-color: var(--color-success);

  font-size: var(--fs-fluid-md);
  font-weight: var(--font-weight-bold);
  color: white;
}

.hs-error-msgs {
  display: block;
  padding: 1rem;
  margin: var(--padding-xs) 0 0;

  font-weight: var(--font-weight-bold);
  font-size: var(--fs-fluid-sm);
  color: white;

  background-color: var(--color-error);

    li + li {
      margin: 1rem 0 0;
    }

}

.hs-form-field .hs-error-msgs label {
  margin: 0;
  color: white;
}

.hs-form-radio-display,
.hs-form-checkbox-display,
.hs-form-booleancheckbox-display {
  cursor: pointer;
}

.hs-form-booleancheckbox p a {
  color: inherit;
}

.legal-consent-container .hs-form-booleancheckbox-display>span {
  margin-left: 25px !important;
}

