.pg-header-outer {
  padding: 0 var(--padding-md);
}

.eyebrow-nav {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  height: 4rem;
  padding: 0 0 0 var(--padding-sm);

  background-color: var(--color-purple-light);

    @include mq(screen-sm) {
      justify-content: flex-end;
      gap: var(--padding-md);
    }

}

.eyebrow-nav__list {
  display: flex;
  gap: 1rem;

    a {
      font-size: var(--fs-fluid-sm);
      font-weight: var(--font-weight-bold);
      letter-spacing: var(--minus-20);
      text-decoration: none;
      color: var(--color-blue-dark);
      opacity: var(--hover-opacity);

        @include mq(screen-sm) {
          padding: 0 2.5rem 0 0;

          background: url('svg/ext-link-blue-dark.svg') no-repeat right center;
          background-size: 1.5rem 1.5rem;
        }
    }

    a:hover {
      text-decoration: underline;
      opacity: 1;
    }

    @include mq(screen-sm) {
      gap: var(--padding-md);
    }

}

.toggle-language-switcher {
  display: flex;
  align-items: center;
  height: 4rem;
  gap: 1rem;
  padding: 0 1rem;

  font-size: var(--fs-fluid-sm);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  text-decoration: none;
  color: var(--color-blue-dark);
  line-height: 4rem;
  opacity: var(--hover-opacity);

    &:hover,
    &.is-active {
      background-color: var(--color-blue-dark);

      color: white;
      opacity: 1;
    }

}

.toggle-language-switcher__flag {
  display: block;

    @include mq(screen-sm) {
      display: none;
    }
  
    svg {
      width: 2.4rem;
    }

}

.toggle-language-switcher__label {
  display: none;

    @include mq(screen-sm) {
      display: block;
    }

}

.toggle-language-switcher__arrow {
  display: block;

    svg {
      width: 1rem;
      height: 1rem;

      transform: rotate(90deg);

      fill: currentColor;
    }

}

.language-switcher {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;

  display: none;
  align-content: start;
  gap: 1rem;
  padding: 1rem;
  width: 20rem;

  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0,0.05), 0px 4px 8px rgba(0, 0, 0,0.05);

    @include mq(screen-sm) {
      width: 30rem;
    }

    &.is-open {
      display: grid;
    }

}

.language-switcher-list {
  display: grid;
  gap: 1rem;
}

.switcher-list__hide {
  display: none;
}

.switcher-list__radio {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;

  font-weight: var(--font-weight-bold);
  text-decoration: none;
  color: var(--color-blue-dark);

    &:hover {

      .switcher-list__label {
        text-decoration: underline;
      }

    }

}

.switcher-list__radio--selected {
  background-color: var(--color-lumo-green);

    .switcher-list__check {
      display: grid;
    }

}

.switcher-list__flag {
  width: 2.4rem;

    svg {
      width: 100%;
    }

}

.switcher-list__check {
  display: none;
  place-items: center;
  flex-shrink: 0;

  width: 1.6rem;
  height: 1.6rem;

  border-radius: 100%;

    svg {
      width: 1rem;
      height: 1rem;

      fill: var(--color-blue-dark);
    }

}

.pg-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: var(--padding-md) 0;
}

.pg-header--with-border {
  margin: 0 0 var(--padding-md);
  border-bottom: 1px solid var(--color-purple-light);
}

.pg-header-logo {
  display: block;
  width: 12rem;

    @include mq(screen-lg) {
      width: 16rem;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

}

.main-nav-toggle {
  display: flex;
  gap: 1rem;
  align-items: center;

  background: none;
  border: none;

  font-size: 1.8rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-dark);

    svg {
      width: 2rem;
      height: 2rem;

      fill: currentColor;
    }

    @include mq(screen-lg) {
      display: none;
    }

}

.main-nav-outer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  width: 100%;
  height: 100%;
  padding: 6rem 0 0;
  overflow-y: scroll;

  background-color: var(--color-purple-light);

    &.is-open {
      display: block;
    }

    @include mq(screen-lg) {
      position: static;

      display: block;
      width: auto;
      height: auto;
      padding: 0;

      background: none;
      box-shadow: none;
      overflow-y: visible;
    }

}

.main-nav-close {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 6rem;
  padding: 0 2rem;

  font-size: 2.4rem;
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  color: var(--color-blue-dark);

  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0,0.05), 0px 4px 8px rgba(0, 0, 0,0.05);

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: currentColor;
    }

    @include mq(screen-lg) {
      display: none;
    }

}

.main-nav {
  padding: 2rem;

    @include mq(screen-lg) {
      padding: 0;
    }

}

.main-nav__list {
  display: flex;
  flex-direction: column;
  gap: 2rem;

    @include mq(screen-lg) {
      flex-direction: row;
      gap: 0;
    }

}

.pg-header-btn {
  display: none;

    @include mq(screen-lg) {
      display: block;
      padding: 1rem 2rem;

      background-color: var(--color-lumo-green);

      font-size: 1.8rem;
      font-weight: var(--font-weight-bold);
      color: var(--color-blue-dark);
      letter-spacing: var(--minus-20);
      text-decoration: none;

        &:hover {
          background-color: var(--color-blue-dark);
          color: white;
        }
    }

}

// Nav item styles

.main-nav__list a {
  text-decoration: none;
}

.main-nav__list li a:not([href="#"]) {
  font-size: 1.8rem;
  color: var(--color-blue-dark);
  opacity: var(--hover-opacity);

    &:hover {
      opacity: 1;
      text-decoration: underline;
    }

}

.main-nav__list li.parent > a {
  display: block;
  font-size: 2.4rem;
  color: var(--color-blue-dark);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  text-decoration: none;
  cursor: pointer;

  opacity: 1;

    @include mq(screen-lg) {
      padding: 1rem 2rem;
      font-size: 1.8rem;

        &:hover {
          text-decoration: underline;
        }

    }

    @include mq(screen-xl) {
      padding: 1rem 3rem;
    }

}

.main-nav__list li.parent > a[href="#"] {
  &:after {
    content: "";

    position: absolute;
    top: 1.5rem;
    right: 1rem;
    transform: translateY(-50%);
    z-index: 1;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: var(--color-blue-dark) transparent transparent transparent;

      @include mq(screen-lg) {
        display: none;
      }

  }

}

// Sub nav styles and activation

.main-nav__list li > .sub-menu.is-open {
  margin: 2rem 0 0;
  padding: 0 0 0 3rem;

  border-left: 2px solid var(--color-blue-dark);

    @include mq(screen-lg) {
      margin: 0;
      padding: 0;
      border: none;
    }

}

.main-nav__list li > .sub-menu li:not(.solutions-sub-title) + li:not(.solutions-sub-title),
.main-nav__list li > .sub-menu .sub-menu li:not(.solutions-sub-title) + li:not(.solutions-sub-title) {
  margin: 1.5rem 0 0;
}

.main-nav__list a[href=""] + .sub-menu {
  display: grid;
  gap: 1.5rem;
}

.main-nav__list > li {
  position: relative;
}

.main-nav__list li.parent > .sub-menu {
  display: none;

    @include mq(screen-lg) {
      padding: 3rem;
      background-color: var(--color-purple-light);
    }

 }

 .main-nav__list li.parent > .sub-menu.is-open {
  display: grid;


    @include mq(screen-lg) {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 10;
    }

}

.main-nav__list > li:not(.parent--solutions) > .sub-menu {
  
  @include mq(screen-lg) {
    width: 30rem;
  }

}

.main-nav__list > li.parent--solutions > .sub-menu {
  gap: 2rem;
  
  @include mq(screen-lg) {
    margin: 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    width: 70rem;
  }

  @include mq(screen-xl) {
    width: 90rem;
  }

}

.main-nav__list > li.parent > a.is-active {

  @include mq(screen-lg) {
    background-color: var(--color-purple-light);
  }

}

.main-nav__list > li.parent--platform > .sub-menu {
  
  @include mq(screen-lg) {
    border-bottom: 5px solid var(--color-platform);
  }

}

.main-nav__list > li.parent--solutions > .sub-menu {
  
  @include mq(screen-lg) {
    border-bottom: 5px solid var(--color-solutions);
  }

}

.main-nav__list > li.parent--services > .sub-menu {
  
  @include mq(screen-lg) {
    border-bottom: 5px solid var(--color-services);
  }

}

.main-nav__list li.parent--resources > .sub-menu,
.main-nav__list li.parent--about > .sub-menu {
  
  @include mq(screen-lg) {
    border-bottom: 5px solid var(--color-lumo-green);
  }

}

.main-nav__list li.solutions-sub-title > a {
  display: block;
  margin: 0 0 0.5rem;

  font-size: 1.8rem;
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  color: var(--color-blue-dark);

  cursor: text;

    @include mq(screen-lg) {
      margin: 0 0 1.5rem;

        &:focus {
          color: var(--color-blue-dark);
        }

        &:visited {
          color: var(--color-blue-dark);
        }

    }
}


