.hero {
  background-color: var(--color-blue-dark);

    @include mq(screen-md) {
      display: grid;
      grid-template-columns: 40% 60%;
      min-height: 50rem;
    }

}

.hero-text {
  position: relative;

  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: center;
  padding: var(--padding-lg);

  background-color: var(--color-blue-dark);

    @include mq(screen-md) {
      padding: var(--padding-lg) var(--padding-lg) var(--padding-xl);
    }

    .btn {
      margin: 1rem 0 0;

      @include mq(screen-md) {
        position: absolute;
        bottom: 0;
        left: var(--padding-lg);
        z-index: 1;

        margin: 0;
      }

    }
    
}

.hero .tag {

  @include mq(screen-xl) {
    position: absolute;
    top: var(--padding-md);
    left: var(--padding-lg);
    z-index: 1;
  }

}

.hero-img {
  @include mq(screen-md) {
    height: 100%;
    //aspect-ratio: 16/9;
  }
}

.hero-img__img {
  display: block;
  width: 100%;
  height: auto;

    @include mq(screen-md) {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

}