.glossary-outer {
  display: grid;
  gap: var(--padding-lg);
  
    @include mq(screen-sm) {
      display: grid;
      gap: var(--padding-xl);
      grid-template-columns: 2fr 1fr;
    }

}

.glossary {
  order: 2;

    @include mq(screen-sm) {
      order: 1;
    }

}

.glossary-nav-outer {
  order: 1;

    @include mq(screen-sm) {
      order: 2;
      position: relative;
    }

    .filters {
      
      @include mq(screen-sm) {
        position: sticky;
        top: 0;
      }

    }

    .filter {
      width: 4rem;
      padding: 0.5rem 1rem;

      text-align: center;
    }
    
}

.glossary-section-marker {
  display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;

  font-size: var(--fs-fluid-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-dark);

  background-color: var(--color-purple-light);
}

.glossary-item {
  padding: var(--padding-lg);
  margin: 0 0 var(--padding-xs);

  background-color: var(--color-purple-light);
}