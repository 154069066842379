.ligentix-carousel-outer {
  position: relative;

    @include mq(screen-sm) {
      padding: 0 var(--padding-md);
    }

    .tns-outer {
      margin: 0 4rem;

        @include mq(screen-sm) {
          margin: 0;
        }

    }

}

.ligentix-carousel {
  background-color: var(--color-purple-light);
}

.ligentix-carousel-controls {
  position: absolute;
  top: calc(50% - 2rem);
  left: 0;
  z-index: 3;

  display: flex;
  justify-content: space-between;

  width: 100%;

    @include mq(screen-sm) {
      display: none;
    }

}

.ligentix-carousel-control {
  width: 4rem;
  height: 4rem;
  padding: 1rem;

  background: none;
  border: none;

    svg {
      fill: var(--color-blue-dark);
    }

}

.ligentix-carousel-control--prev svg {
  transform: rotate(180deg);
}

.ligentix-carousel-nav-outer {
  display: none;
  padding: var(--padding-md);

  background-color: var(--color-purple-light);

    @include mq(screen-sm) {
      display: block;
    }

    @include mq(screen-md) {
      padding: var(--padding-lg) var(--padding-lg) 0;
    }

}

.ligentix-carousel-nav {
  position: relative;

  display: flex;
  justify-content: space-evenly;

    &:after {
      content: "";

      position: absolute;
      top: calc(6rem + 3px);
      left: 0;
      z-index: 1;

      display: block;
      width: 100%;
      height: 1px;

      background-color: var(--color-blue-dark);
    }

}

.ligentix-carousel-nav--3 {
  
  .ligentix-carousel-nav__toggle {
    width: 33.333%;
  }

}

.ligentix-carousel-nav--4 {
  
  .ligentix-carousel-nav__toggle {
    width: 25%;
  }

}

.ligentix-carousel-nav--5 {
  
  .ligentix-carousel-nav__toggle {
    width: 20%;
  }

}

.ligentix-carousel-nav__toggle {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  text-align: center;

  background: none;

    &:hover {
      text-decoration: underline;
    }

    &.tns-nav-active {

      .ligentix-carousel-nav__marker {
        background-color: var(--color-lumo-green);
      }

    }

}

.ligentix-carousel-nav__icon {
  display: block;
  width: 5rem;
  height: 5rem;
}

.ligentix-carousel-nav__marker {
  display: block;
  width: 8rem;
  height: 6px;

  background-color: var(--color-blue-dark);
}

.ligentix-carousel__slide__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: var(--padding-sm) var(--padding-md) 0;

    @include mq(screen-sm) {
      display: none;
    }

}

.ligentix-carousel__slide__content {
  display: grid;
  gap: var(--padding-md);
  padding: var(--padding-sm) var(--padding-md) var(--padding-lg);

    @include mq(screen-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-md) {
      grid-template-columns: 1.5fr 1fr;
      gap: var(--padding-lg);
      padding: var(--padding-lg);
    }

}

.ligentix-carousel__slide__img {
  display: block;
  width: 100%;
  height: auto;
}

.ligentix-carousel__slide__text h2 {
  display: none;

    @include mq(screen-sm) {
      display: block;
    }

}


