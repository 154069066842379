.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--padding-lg);

    @include mq(screen-sm) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }

}

.stat {
  width: 80%;

  text-align: center;

    @include mq(screen-xs) {
      width: 30rem;
    }

}

.stat__value {
  display: block;
  padding: 0 0 0.5rem;
  margin: 0 0 var(--padding-sm);

  border-bottom: 3px solid var(--color-lumo-green);

  font-weight: var(--font-weight-bold);
  font-size: var(--fs-fluid-max);
  color: var(--color-blue-dark);
  line-height: 1;
  letter-spacing: var(--minus-50);

    sup {
      margin: 0 0 0 0.5rem;
      font-size: 50%;
      font-weight: var(--font-weight-bold);
    }
    
}

.stat__label {
  padding: 0 var(--padding-sm);

  line-height: var(--line-height-md);
}

