.cta {
  position: relative;

  background-color: var(--color-blue-dark);
  overflow: hidden;
  
  @include mq(screen-sm) {
    display: flex;
    height: 50rem;
  }

}
.cta-text {
  position: relative;
  z-index: 1;

  display: grid;
  gap: var(--padding-md);
  justify-items: start;
  align-content: center;

  width: 100%;
  padding: var(--padding-lg);

    .btn {
      margin: var(--padding-sm) 0 0;
    }

}

.cta-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  opacity: 0.25;
  filter: grayscale(1);

    @include mq(screen-sm) {
      position: relative;

      opacity: 1;
      filter: grayscale(0);
    }

}

.cta-text,
.cta-img {
  
  @include mq(screen-sm) {
    width: 50%;
  }

}


.cta-img__img {
  display: block;
  width: 100%;
  height: auto;

    @include mq(screen-sm) {
      height: 100%;

      object-fit: cover;
    }

}

.cta--std {
  
  .cta-img__img {
  
    @include mq(screen-sm) {
      object-position: left center;
    }
  
  }

}

.cta--alt {

  .cta-text {
    
    @include mq(screen-sm) {
      order: 2;
    }

  }
  
  .cta-img  {
  
    @include mq(screen-sm) {
      order: 1;
    }

  }

  .cta-img__img {
  
    @include mq(screen-sm) {
      object-position: right center;
    }
  
  }

}