.staff-testimonials-outer {
  position: relative;
  z-index: 1;

  padding: var(--padding-xl) 0;
  background-color: var(--color-blue-dark);

    > .inner {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      background: url(img/topography-gradient-1.png) no-repeat right -600px center;
      background-size: auto 500px;

      opacity: 0.25;
      filter: grayscale(1);

        @include mq(screen-xl) {
          opacity: 1;
          filter: grayscale(0);
        }

        @include mq(screen-lg) {
          background-size: auto 750px;
        }

    }

}

.staff-testimonials {
  display: grid;
  gap: var(--padding-lg);

  max-width: var(--mq-sm);
  margin: 0 auto;
  padding: 0 var(--padding-md);
}

.staff-testimonial {
  display: grid;
  gap: var(--padding-sm);
}

.staff-testimonial + .staff-testimonial {
  padding: var(--padding-lg) 0 0;

  border-top: 1px solid var(--color-blue-mid);
}