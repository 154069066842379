.gated-cta {
  display: grid;
  gap: var(--padding-lg);

    @include mq(screen-sm) {
      grid-template-columns: 2fr 1fr;
    }

    @include mq(screen-md) {
      gap: var(--padding-xl);
    }

}

.gated-cta-content {
  order: 2;

  display: grid;
  gap: var(--padding-lg);

    @include mq(screen-sm) {
      order: 1;
    }

}

.gated-cta-img {
  order: 1;

    @include mq(screen-sm) {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      order: 2;
    }

}

.gated-cta-img__img {
  display: block;
  width: 20rem;
  height: auto;

    @include mq(screen-sm) {
      width: 75%;
    }

}