/*!
Theme Name: Ligentia
Theme URI: 
Author: Ride Shotgun
Author URI: http://www.rideshotgun.co.uk
Description: 
Version: 2.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/

:root {
  --fluid-min-width: 320;
  --fluid-max-width: 1600;
  --fluid-screen: 100vw;
  --fluid-bp: calc((var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) / (var(--fluid-max-width) - var(--fluid-min-width)));

  --f-0-min: 18;
  --f-0-max: 21;
  --fs-fluid-xs: calc(
    ((var(--f-0-min) / 16) * 1rem) + (var(--f-0-max) - var(--f-0-min)) *
      var(--fluid-bp)
  );

  --f-1-min: 21;
  --f-1-max: 24;
  --fs-fluid-sm: calc(
    ((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) *
      var(--fluid-bp)
  );

  --f-2-min: 24;
  --f-2-max: 30;
  --fs-fluid-md: calc(
    ((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) *
      var(--fluid-bp)
  );

  --f-3-min: 27;
  --f-3-max: 36;
  --fs-fluid-lg: calc(
    ((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) *
      var(--fluid-bp)
  );

  --f-4-min: 30;
  --f-4-max: 45;
  --fs-fluid-xl: calc(
    ((var(--f-4-min) / 16) * 1rem) + (var(--f-4-max) - var(--f-4-min)) *
      var(--fluid-bp)
  );

  --f-5-min: 33;
  --f-5-max: 54;
  --fs-fluid-xxl: calc(
    ((var(--f-5-min) / 16) * 1rem) + (var(--f-5-max) - var(--f-5-min)) *
      var(--fluid-bp)
  );

  --f-6-min: 40.00;
  --f-6-max: 95.00;
  --fs-fluid-max: calc(
    ((var(--f-6-min) / 16) * 1rem) + (var(--f-6-max) - var(--f-6-min)) *
      var(--fluid-bp)
  );

  --padding-xl-min: 60;
  --padding-xl-max: 120;
  --padding-xl: calc(((var(--padding-xl-min) / 16) * 1rem) + (var(--padding-xl-max) - var(--padding-xl-min)) * var(--fluid-bp));

  --padding-lg-min: 40;
  --padding-lg-max: 80;
  --padding-lg: calc(((var(--padding-lg-min) / 16) * 1rem) + (var(--padding-lg-max) - var(--padding-lg-min)) * var(--fluid-bp));

  --padding-md-min: 20;
  --padding-md-max: 40;
  --padding-md: calc(((var(--padding-md-min) / 16) * 1rem) + (var(--padding-md-max) - var(--padding-md-min)) * var(--fluid-bp));

  --padding-sm-min: 15;
  --padding-sm-max: 30;
  --padding-sm: calc(((var(--padding-sm-min) / 16) * 1rem) + (var(--padding-sm-max) - var(--padding-sm-min)) * var(--fluid-bp));

  --padding-xs-min: 10;
  --padding-xs-max: 20;
  --padding-xs: calc(((var(--padding-xs-min) / 16) * 1rem) + (var(--padding-xs-max) - var(--padding-xs-min)) * var(--fluid-bp));

  --font-weight-bold: 700;

  --line-height-sm: 1.25;
  --line-height-md: 1.5;
  --line-height-lg: 1.75;

  --color-blue-dark: #053345;
  --color-blue-type: rgba(5, 51, 69, 0.8);
  --color-blue-mid: #7ec0ce;
  --color-blue-light: #f2f9fa;
  --color-blue-light-fade: rgba(126,192,206,0.1);
  --color-lumo-green: #89ffbc;
  --color-purple: #786eb0;
  --color-purple-light: #f1f0f7;
  --color-purple-light-fade: rgba(120,110,176,0.1);
  --color-pink-light: #FDE9F1;
  --color-platform: #b40089;
  --color-solutions: #7832b0;
  --color-services: #336ee0;
  --color-white-type: rgba(255,255,255, 0.8);

  --color-error: #C60000;
  --color-success: #90B327;

  --minus-20: -0.02em;
  --minus-50: -0.05em;
  --hover-opacity: 0.8;

  --mq-xs: 640px;
  --mq-sm: 768px;
  --mq-md: 1024px;
  --mq-lg: 1280px;
  --mq-xl: 1536px;
  --mq-xxl: 1800px;
}

@media screen and (min-width: 1280px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}

@import "config/fonts";
@import "config/variables";
@import "config/mixins";
@import "config/media-queries";

@import "global/reset";
@import "global/helpers";
@import "global/layout";
@import "global/spacing";
@import "global/header";
@import "global/footer";
@import "global/typography";
@import "global/btn";

@import "components/std-content";
@import "components/section-intro";
@import "components/statistics-grid";
@import "components/features-overview";
@import "components/client-grid";
@import "components/media-summary";
@import "components/related-content";
@import "components/hero";
@import "components/cta";
@import "components/solutions-carousel";
@import "components/services-list";
@import "components/testimonials-carousel";
@import "components/mini-cta";
@import "components/text-img";
@import "components/form-fields";
@import "components/tags";
@import "components/home-hero";
@import "components/feed-hero";
@import "components/pagination";
@import "components/vacancies-list";
@import "components/staff-testimonials";
@import "components/glossary";
@import "components/filters";
@import "components/img-banner";
@import "components/gated-content";
@import "components/quote-form";
@import "components/bio-intro";
@import "components/tables";
@import "components/ligentix-carousel";
@import "components/document-grid";

// @import "plugins/lite-yt-embed";
@import "plugins/theme-my-login";
@import "plugins/tiny-slider";
