// =================================================
// SPACING STYLES
// =================================================

// Margin top

.mt-xs {
  margin-top: var(--padding-xs);
}

.mt-sm {
  margin-top: var(--padding-sm);
}

.mt-md {
  margin-top: var(--padding-md);
}

.mt-lg {
  margin-top: var(--padding-lg);
}

.mt-xl {
  margin-top: var(--padding-xl);
}

// Margin bottom

.mb-xs {
  margin-bottom: var(--padding-xs);
}

.mb-sm {
  margin-bottom: var(--padding-sm);
}

.mb-md {
  margin-bottom: var(--padding-md);
}

.mb-lg {
  margin-bottom: var(--padding-lg);
}

.mb-xl {
  margin-bottom: var(--padding-xl);
}

// Margin top/bottom

.mtb-xs {
  margin: var(--padding-xs) 0;
}

.mtb-sm {
  margin: var(--padding-sm) 0;
}

.mtb-md {
  margin: var(--padding-md) 0;
}

.mtb-lg {
  margin: var(--padding-lg) 0;
}

.mtb-xl {
  margin: var(--padding-xl) 0;
}

// Margin 

.m-xs {
  margin: var(--padding-xs);
}

.m-sm {
  margin: var(--padding-sm);
}

.m-md {
  margin: var(--padding-md);
}

.m-lg {
  margin: var(--padding-lg);
}

.m-xl {
  margin: var(--padding-xl);
}

// Padding top

.pt-xs {
  padding-top: var(--padding-xs);
}

.pt-sm {
  padding-top: var(--padding-sm);
}

.pt-md {
  padding-top: var(--padding-md);
}

.pt-lg {
  padding-top: var(--padding-lg);
}

.pt-xl {
  padding-top: var(--padding-xl);
}

// Padding bottom

.pb-xs {
  padding-bottom: var(--padding-xs);
}

.pb-sm {
  padding-bottom: var(--padding-sm);
}

.pb-md {
  padding-bottom: var(--padding-md);
}

.pb-lg {
  padding-bottom: var(--padding-lg);
}

.pb-xl {
  padding-bottom: var(--padding-xl);
}

// Padding top/bottom

.ptb-xs {
  padding: var(--padding-xs) 0;
}

.ptb-sm {
  padding: var(--padding-sm) 0;
}

.ptb-md {
  padding: var(--padding-md) 0;
}

.ptb-lg {
  padding: var(--padding-lg) 0;
}

.ptb-xl {
  padding: var(--padding-xl) 0;
}

// Padding 

.p-xs {
  padding: var(--padding-xs);
}

.p-sm {
  padding: var(--padding-sm);
}

.p-md {
  padding: var(--padding-md);
}

.p-lg {
  padding: var(--padding-lg);
}

.p-xl {
  padding: var(--padding-xl);
}
