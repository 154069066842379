.btn {
  display: inline-flex;
  gap: 1.5rem;
  align-items: center;
  min-height: 3.5rem;
  padding: 0.5rem 1.5rem;

  font-family: 'Manrope', sans-serif;
  font-size: var(--fs-fluid-sm);
  font-weight: var(--font-weight-bold);
  text-decoration: none;

  cursor: pointer;
  border: none;

    svg {
      flex-shrink: 0;
      width: 1.5rem;
      height: 1.5rem;

      fill: currentColor;
    }

    &:hover {
      text-decoration: underline;

        svg {
          transform: translateX(0.15rem);
        }

    }

}

.btn--blue-dark {
  color: white;

  background-color: var(--color-blue-dark);

    &:hover {
      color: var(--color-lumo-green);
    }

}

.btn--lumo-green {
  color: var(--color-blue-dark);
  background-color: var(--color-lumo-green);
}

// Text buttons

.text-btn {
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);

  cursor: pointer;
}

button.text-btn,
input.text-btn {
  border: none;
  background: none;

  text-decoration: underline;
}

.text-btn--icon {
  display: flex;
  align-items: center;
  gap: 1rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: currentColor;
    }
}

.text-btn--blue-dark {
  color: var(--color-blue-dark);

    &:hover {
      color: var(--color-purple);
    }

}


.text-btn--lumo-green {
  color: var(--color-lumo-green);

    &:hover {
      color: white;
    }
    
}

// Icon buttons

.icon-btn {
  display: inline-flex;
  align-items: center;
  gap: 1rem;

  font-weight: var(--font-weight-bold);
  letter-spacing: var(--minus-20);
  text-decoration: none;

  cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: currentColor;
    }

}

.icon-btn--blue-dark {
  color: var(--color-blue-dark);
}

.icon-btn-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-sm) var(--padding-lg);
}
