.section-intro {
  display: grid;
  gap: var(--padding-sm);
  justify-items: center;

  padding: 0 var(--padding-lg);
  margin: 0 auto var(--padding-lg);

  text-align: center;

    h2 {
      max-width: var(--mq-lg);
    }

    p {
      max-width: var(--mq-xs);
    }

}
